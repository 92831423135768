import { Calendar, Box, Grid, Text, Button } from 'grommet';
import React, { useState, useRef } from 'react';

import { useUsers } from '../../context/users';
import { SubHeader } from '../../components';
import CreateAppointmentModal from '../../components/Modals/CreateAppointmentModal';

const CalendarPage = (): JSX.Element => {
  const [date, setDate] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const { appointments2 } = useUsers();

  const dayRefs = useRef({});
  const gridContainerRef = useRef<HTMLDivElement>(null); // Ref for the scrollable container

  const onSelect = (nextDate) => {
    setDate(nextDate !== date ? nextDate : undefined);

    const x = new Date(nextDate);
    const y = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(x);

    scrollToDate(y);
    console.log(y);
  };

  // Constants for today and end (60 days later)
  const today = new Date();
  const todayString = today.toLocaleDateString('en-CA'); // YYYY-MM-DD in local time

  const endDate = new Date();
  endDate.setDate(today.getDate() + 60);
  const endDateString = endDate.toLocaleDateString('en-CA');

  // Generates array of 60 days, starting from today
  const generateDaysArray = () => {
    const daysArray: Date[] = [];
    let current = new Date(today);

    while (current <= endDate) {
      daysArray.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return daysArray;
  };

  // Generate all days and merge with appointments
  const allDays = generateDaysArray();
  const mergedData = allDays.map((day) => {
    const dayString = day.toLocaleDateString('en-CA');
  
    return {
      date: day.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
      appointments: appointments2[dayString] || [],
    };
  });

  const scrollToDate = (date) => {
    if (dayRefs.current[date] && gridContainerRef.current) {
      const targetElement = dayRefs.current[date];
      const container = gridContainerRef.current;

      // Calculate the scroll offset relative to the container
      const offsetTop = targetElement.offsetTop - container.offsetTop;
      container.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
    console.log(date);
  };

  return (
    <>
      <SubHeader title="Calendar" />
      <Box direction="row" gap="xlarge" pad="small" justify="center">
        <Box pad="small">
          <Calendar
            date={date}
            daysOfWeek
            onSelect={onSelect}
            size="medium"
            bounds={[todayString, endDateString]}
          />
        </Box>

        {/* Scrollable container for the appointments list */}
        <Box
          ref={gridContainerRef}
          overflow={{ vertical: 'auto' }}
          width="large"
          height={{ max: 'large' }}
          style={{ maxHeight: '70vh' }} // Ensure scroll container is properly set
        >
          <Grid rows="auto" gap="medium">
            {mergedData.map((day) => (
              <Box key={day.date} ref={(el) => (dayRefs.current[day.date] = el)} pad="small" border={{ color: 'light-4', size: 'small' }}>
                {/* Day header */}
                <Text size="small" weight="bold">{day.date}</Text>
                {/* Appointments for the day */}
                {day.appointments.map((appt, index) => (
                  <Box key={index} background="light-3" pad="small" margin={{ top: 'small' }}>
                    <Text color="dark-2">{appt.time}</Text>
                    <Text weight="bold">{appt.type}</Text>
                  </Box>
                ))}
                {/* Block time button (always displayed) */}
                <Box border={{ style: 'dashed', color: 'light-3' }} pad="small" margin={{ top: 'small' }}>
                  <Button color="light-4" onClick={() => {
                      setShowModal(prev => !prev)
                      setSelectedDate(day.date);
                    }}>
                    Block time
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>
      {showModal && selectedDate && (
        <CreateAppointmentModal setShow={setShowModal} date={selectedDate}/>
      )}
    </>
  );
};

export default CalendarPage;
