import { Footer as GrommetFooter, Box, Text, Image, Anchor } from 'grommet';
import React from 'react';
import {
  Grommet as GrommetIcon,
  LinkedinOption,
  Instagram,
  Twitter,
} from 'grommet-icons';

import '../website/styles/styles.css'
import appStoreButton from '../assets/images/app-store-button.png'
import googleStoreButton from '../assets/images/google-play-button.png'

const Media = () => (
  <Box direction="row" gap="xxsmall" align="start">
    <Anchor
      a11yTitle="Share feedback on Github"
      href="https://www.instagram.com/"
      icon={<Instagram color="#FFF" />}
    />
    <Anchor
      a11yTitle="LinkedIn"
      href="https://www.linkedin.com/company/bins-storage"
      icon={<LinkedinOption color="#FFF" />}
    />
  </Box>
);

const Footer = (): JSX.Element => (
  <Box
    background="#000"
    pad={{ vertical: 'large', horizontal: 'xlarge' }}
    direction='column'
  >

    <GrommetFooter>
      <Box direction="row" align="center" gap="small">
        <Box width="3rem">
          <Image src="/android-chrome-512x512.png" alt="bins valet storage logo" />
        </Box>
        <Text className='ralewayNoMargin' color="white">Bins Storage</Text>
      </Box>
    </GrommetFooter>  

    <GrommetFooter direction="row" justify="between" wrap={true} align='start' margin={{vertical: "large"}} >
      <Box align="start">
        <Anchor href="/About">
          <Text color="#FFF" className="link">ABOUT</Text>
        </Anchor>
        <Anchor href="/FAQ">
          <Text color="#FFF" className="link">FAQ</Text>
        </Anchor>
        <Anchor href="/Contact">
          <Text color="#FFF" className="link">SUPPORT</Text>
        </Anchor>
        <Anchor href="/Privacy">
          <Text color="#FFF" className="link">PRIVACY POLICY</Text>
        </Anchor>
      </Box>
      <Box align="start">
        <Text className='ralewaySmall'>DOWNLOAD</Text>
        <Box direction="row" gap="medium">
          <Anchor href="https://apps.apple.com/us/app/bins-storage/id1554718615"><Image className="appStoreButton" src={appStoreButton}/></Anchor>
          <Anchor href="https://apps.apple.com/us/app/bins-storage/id1554718615"><Image className="appStoreButton" src={googleStoreButton}/></Anchor>
        </Box>
      </Box>
      <Box align="start">
        <Text className='ralewaySmall' margin={{left: "small"}}>FOLLOW US</Text>
        <Media />
      </Box>
      <Box align="start">
        <Text className='ralewaySmall'>CONTACT US</Text>
        <Text margin={{top: "small"}} className='ralewayNoMargin'>contact@bins-storage.com</Text>
      </Box>
    </GrommetFooter>

    <GrommetFooter>
      <Box direction="row" align='center' justify="center">
        <Text className="ralewayNoMargin">© 2024 Bins Storage, Inc.</Text>
      </Box>
    </GrommetFooter>

  </Box>
);

export default Footer;
