import { Box, FileInput, Text, TextArea } from 'grommet';
// import { Edit } from 'grommet-icons';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

import Modal from './Modal';
import StyledButton from '../StyledButton';

// TODO: probably want data validation so it's not too big
// prolly on server side

const FeedbackModal = ({
  setShow,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const [input, setInput] = useState('');

  const confirmSubmit = () => {
    // do async edit logic and error catching
    if (input.length !== 0 || input.trim()) {
      toast.success('Thank you for the feedback!');
      setShow(false);
    } else {
      toast.error('Please input some feedback');
    }
  };

  return (
    <Modal
      setShow={setShow}
      header="Do you have a comment or suggestion about Bins?"
    >
      <Box gap="medium" pad={{ horizontal: 'large' }}>
        <Box direction="column" gap="medium">
          <Box height="small" gap="small">
            <Text>
              Please tell us what you&apos;d like to see on your portal to make
              operating valet storage smoother for your business.
            </Text>

            <TextArea
              value={input}
              onChange={event => setInput(event.target.value)}
              resize={false}
              maxLength={500}
              fill
            />
          </Box>

          <Box align="center" gap="small" fill>
            <Text>Upload Photo? (Optional)</Text>
            <Box width="medium">
              {/* TODO: style, customize more with themecontext.extend */}
              {/* TODO: do something with the file */}
              <FileInput />
            </Box>
          </Box>
        </Box>
        <Box direction="row" justify="center">
          <StyledButton label="Submit" onClick={confirmSubmit} />
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
