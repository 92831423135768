import { Deliver } from 'grommet-icons';
import React from 'react';

import { DriverCardGrid, SubHeader, DriverCard } from '../../components';
import { useUsers, Driver } from '../../context/users';

const Drivers = (): JSX.Element => {
  const { drivers } = useUsers();
  return (
    <>
      <SubHeader title="Drivers" icon={<Deliver />} />
      <DriverCardGrid
        data={drivers}
        card={(user: Driver) => (
          <DriverCard key={user.id} user={user} type="driver" />
        )}
      />
    </>
  );
};

export default Drivers;
