import { Box, Text } from 'grommet';
import React from 'react';

import { User } from '../../../context/model/types';
import { useUsers } from '../../../context/users';

const CustomerDetails = ({ user }: { user: User }): JSX.Element => {
  
  const { units } = useUsers();

const getUserUnit = (tenantID) => {
  const user = units.find(unit => unit.tenantID === tenantID);
  return user ? user.sitelinkUnit.sUnitName : null;
}
  return(
  <Box gap="medium">
    <Box gap="small">
      <Text weight="bold">
        Phone:{' '}
        <Text weight="normal" color="dark-3">
          {user.phone}
        </Text>
      </Text>
      <Text weight="bold">
        Email:{' '}
        <Text weight="normal" color="dark-3">
          {user.email}
        </Text>
      </Text>
      <Text weight="bold">
        Address:{' '}
        <Text weight="normal" color="dark-3">
          {user.address.addressLine1} {', '} {user.address.city} {' '} {user.address.state}
        </Text>
      </Text>
      <Text weight="bold">Unit: {' '}
        <Text weight="normal" color="dark-3">
        {getUserUnit(user.id)}
        </Text>
      </Text>
    </Box>
  </Box>
);
};

export default CustomerDetails;
