import { Box } from 'grommet';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { SubHeader, OrdersTable } from '../../components';

const Dashboard = (): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <SubHeader title="Welcome"/>
      <Box gap="large" pad={{ top: 'medium' }}>
        {/* have stuff before the orderstable so it doesn't look just like orders page */}
        <OrdersTable type="Pending Orders" />
        <OrdersTable type="Today's Orders" />
      </Box>
    </>
  );
};

export default Dashboard;
