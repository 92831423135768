/* eslint-disable no-continue */
import { API } from 'aws-amplify';

import { listTenants } from '../../graphql/queries';
import { GetTenantsQueryResult } from '../../graphql/types';
import { User } from '../model/types';

// Helper function to turn all the fetched tenants into the admin app-specific User type
const appsyncTenantsToUserType = (listTenantsQueryResult: GetTenantsQueryResult): User[] => {
    const { data } = listTenantsQueryResult
    if (!data.listTenants) {
        return []
    }

    // Convert all the tenant info into the app-specific User type by removing all the optional / null / undefined values
    const users: User[] = []
    const fetchedTenants = data.listTenants.items
    console.log('Fetched tenants length: ', fetchedTenants.length)
    for (let i = 0; i < fetchedTenants.length; i+=1) {
        const tenant = fetchedTenants[i]
        if (!tenant) {
            console.warn('Found null tenant in appsync listTenants response.')
            continue;
        }
        // Thanks Appsync...thanks a lot for the optionals!!
        // TODO(typing): should our data really be this optional...
        if (!tenant.id || !tenant.facilityID || !tenant.name || !tenant.email || !tenant.phone ||
            !tenant.address || !tenant.address.addressLine1 || !tenant.address.city || !tenant.address.state || !tenant.address.zip ||
            !tenant.tenantID || !tenant.accessCode || !tenant.plan || !tenant.plan.minStay || !tenant.plan.typeOfPlan || !tenant.plan.size || !tenant.plan.price) {
            console.warn('Found tenant with missing data. Will skip instead of processing...')
            continue;
        }
        users.push({
            id: tenant.id,
            facilityID: tenant.facilityID,
            name: tenant.name,
            email: tenant.email,
            phone: tenant.phone,
            address: {
                addressLine1: tenant.address.addressLine1,
                addressLine2: tenant.address.addressLine2 ?? '',
                city: tenant.address.city,
                state: tenant.address.state,
                zip: tenant.address.zip,
                specialInstructions: tenant.address.specialInstructions ?? '',
                building: tenant.address.building ?? '',
                parking: tenant.address.parking ?? '' 
            },
            unitID: tenant.unitID ?? '',
            sitelinkTenantID: tenant.tenantID,
            accessCode: tenant.accessCode,
            plan: {
                minStay: tenant.plan.minStay,
                typeOfPlan: tenant.plan.typeOfPlan,
                size: tenant.plan.size,
                price: tenant.plan.price,
            }
        });
    }
    console.log(`Processed all fetched tenants from appsync. Found ${users.length} tenants.`)
    return users;
}

export const GetTenants = async (facilityID: string): Promise<User[]> => {
    const listTenantsQueryResult = await API.graphql({query: listTenants, variables: {filter: {facilityID: {eq: facilityID}}}}) as GetTenantsQueryResult;
    return appsyncTenantsToUserType(listTenantsQueryResult)
};
