import { Box, Text } from 'grommet';
import React from 'react';

import { User } from '../../../context/model/types';

const DriverDetails = ({ user }: { user: User }): JSX.Element => (
  <Box gap="medium">
    <>
      <Text weight="bold">
        Company:{' '}
        <Text weight="normal" color="dark-3">
          binsbros
        </Text>
      </Text>
      <Text weight="bold">
        Phone:{' '}
        <Text weight="normal" color="dark-3">
          {user.phone}
        </Text>
      </Text>
      <Text weight="bold">
        Email:{' '}
        <Text weight="normal" color="dark-3">
          {user.email}
        </Text>
      </Text>
    </>

    <Box gap="small">
      <Text weight="bold" style={{ textDecoration: 'underline' }}>
        Orders in Progress:
      </Text>
      <Box gap="medium">
        {Array.from({ length: 3 }, () => ({
          name: 'customer name',
          type: 'pickup/delivery',
        })).map(item => (
          <>
            <Text weight="bold">
              Customer:{' '}
              <Text weight="normal" color="dark-3">
                {item.name}
              </Text>
            </Text>
            <Text weight="bold">
              Order Type:{' '}
              <Text weight="normal" color="dark-3">
                {item.type}
              </Text>
            </Text>
          </>
        ))}
      </Box>
    </Box>
  </Box>
);

export default DriverDetails;
