import { Button, ThemeContext } from 'grommet';
import React from 'react';

// not sure how to type a function
const StyledButton = ({
  label,
  onClick,
}: {
  label?: string;
  // TODO type
  onClick?: any;
}): JSX.Element => (
  <ThemeContext.Extend
    value={{
      button: {
        border: {
          radius: '5px',
        },
        default: {
          background: {
            color: '#7C92A1',
          },
          color: '#FFFFFF',
        },
        hover: {
          background: {
            color: 'brandAccent',
          },
        },
      },
    }}
  >
    <Button label={label} onClick={onClick} />
  </ThemeContext.Extend>
);

export default StyledButton;
