import { Card, CardHeader, CardBody, CardFooter, Text, Box } from 'grommet';
import React from 'react';

const BillingCard = ({
  title,
  children,
  footer,
}: {
  title: string;
  children: JSX.Element;
  footer: JSX.Element;
}): JSX.Element => (
  <Card width="medium">
    <CardHeader background={{ color: 'light-5' }} pad="small">
      <Box pad="xsmall">
        <Text size="large" color="dark-1">
          {title}
        </Text>
      </Box>
    </CardHeader>
    <CardBody pad="small">
      <Box pad="xsmall">{children}</Box>
    </CardBody>
    <CardFooter pad={{ horizontal: 'small', bottom: 'small' }}>
      <Box pad="xsmall">{footer}</Box>
    </CardFooter>
  </Card>
);

export default BillingCard;
