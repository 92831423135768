import React from 'react';

import { InvoiceCard, PaginatedCardGrid, SubHeader } from '../../components';
import { useUsers, User } from '../../context/users';

const Invoices = (): JSX.Element => {
  const { users } = useUsers();

  return (
    <>
      <SubHeader title="Invoices" />
      <PaginatedCardGrid
        data={users}
        card={(user: User) => <InvoiceCard key={user.id} />}
      />
    </>
  );
};

export default Invoices;
