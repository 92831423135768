import { Header as GrommetHeader, Box, Text, Avatar, Drop, Grid } from 'grommet';
import {
  Notification,
  Search,
  Currency,
  SettingsOption,
  Stakeholder,
  Logout,
} from 'grommet-icons';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../context/auth';
import FeedbackModal from '../Modals/FeedbackModal';
import Navbar from './Navbar';
import { useUsers } from '../../context/users';

const Header = (): JSX.Element => {
  // TODO fix type
  const menuRef: any = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const history = useHistory();

  const { user, signOut } = useAuth();
  const { admin } = useUsers();

  return (
    <GrommetHeader background="brand" direction="column" gap="none">
      {/* TODO: Logo alignment with nav bar is hardcoded px */}
      {/* this box's pad and the navbar */}
      <Box
        direction="row"
        justify="between"
        width="100%"
        pad={{ horizontal: '30px', top: 'medium', bottom: 'small' }}
      >
        <Box direction="row" align="center" gap="large">
          <Text size="xlarge" weight="normal">
            Administrator
          </Text>
        </Box>
        <Box direction="row" gap="medium">
          {/*<Box direction="row" gap="medium" align="center">
              <Notification style={{ cursor: 'pointer' }} />
              <Search style={{ cursor: 'pointer' }} />
            </Box>*/}

          <Box
            direction="row"
            gap="small"
            align="center"
            ref={menuRef}
            onMouseEnter={() => setShowMenu(true)}
            style={{ cursor: 'pointer' }}
          >
            <Box align="end">
              <Text size="small">{admin.map(item => (
            <Text>{item.name}</Text>
            ))}
            </Text>
            </Box>
            <Avatar
              src="https://1.gravatar.com/userimage/212100380/bfb34d02a1e842372821360907aa9583?size=256"
              size="medium"
            />
          </Box>
        </Box>
      </Box>
      <Navbar />
      {showFeedback && <FeedbackModal setShow={setShowFeedback} />}

      {showMenu && (
        <Drop
          target={menuRef.current}
          onClickOutside={() => setShowMenu(false)}
          onEsc={() => setShowMenu(false)}
          onMouseLeave={() => setShowMenu(false)} // kinda jank behavior but good enough
          overflow="hidden"
          round="small"
          elevation="none"
          align={{ top: 'bottom', right: 'right' }}
          margin={{ top: 'small' }}
          background={{ color: '#7C92A1' }}
        >
          <Box width="xsmall" direction="column" gap="small" pad="small" fill>
            <Box direction="row" align="center" gap="xsmall">
              <Text size="small" color="white" weight="bold">
                My Account
              </Text>
            </Box>
            {/*<Box direction="row" align="center" gap="xsmall">
              <Currency size="xsmall" color="white" />
              <Text
                size="small"
                color="white"
                style={{ cursor: 'pointer' }}
                // onClick={() => history.push('/billing')}
              >
                Billing
              </Text>
              </Box>*/}
            <Box direction="row" align="center" gap="xsmall">
              <SettingsOption size="xsmall" color="white" />
              <Text
                size="small"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/Admin/settings')}
              >
                Facility
              </Text>
            </Box>
            {/*<Box direction="row" align="center" gap="xsmall">
              <Stakeholder size="xsmall" color="white" />
              <Text
                size="small"
                color="white"
                style={{ cursor: 'pointer' }}
                // onClick={() => setShowFeedback(true)}
              >
                Feedback?
              </Text>
            </Box> */}
            <Box direction="row" align="center" gap="xsmall">
              <Logout size="xsmall" color="white" />
              <Text
                size="small"
                color="white"
                style={{ cursor: 'pointer' }}
                onClick={signOut}
              >
                Logout
              </Text>
            </Box>
          </Box>
        </Drop>
      )}
    </GrommetHeader>
  );
};
export default Header;
