import { Box, Text } from 'grommet';
import React from 'react';
import { Link } from 'react-router-dom';

import { BillingCard } from '../../components';
import { useUsers } from '../../context/users';

const Billing = (): JSX.Element => {
  const { facilityInfo } = useUsers();
 return (
  <Box pad={{ horizontal: 'small' }}>
    <Box direction="column" gap="small" pad={{ bottom: 'medium' }}>
      <Text size="xlarge">Facility</Text>
      <Box fill background={{ color: 'light-4' }} round="xsmall" pad="small">
        <Text>{facilityInfo?.name}</Text>
      </Box>
    </Box>

    <Box direction="row" gap="large">
      <BillingCard
        title="Subscription"
        footer={<Box/>}
        //footer={<Link to="/subscriptions">Manage Subscription</Link>}
      >
        <>
          <Text>PLAN: Bins Basic</Text>
          <ul style={{ marginTop: 0 }}>
            <li>Unlimited units</li>
            <li>$99/month</li>
          </ul>
        </>
      </BillingCard>

      {/*<BillingCard
        title="Billing History"
        footer={<Link to="/billinghistory">View More</Link>}
      >
        <>
          <Text>Janurary 2021</Text>
          <Text>Feburary 2021</Text>
          <Text>March 2021</Text>
          <Text>April 2021</Text>
        </>
      </BillingCard>*/}
    </Box>
  </Box>
 )
};

export default Billing;
