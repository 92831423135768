import { Box } from 'grommet';
import React, { useState } from 'react';
import * as XLSX from "xlsx";

import {
  SubHeader,
  ExportModal,
  StyledButton,
  InventoryTable,
} from '../../components';
import { useUsers } from '../../context/users';

const Inventory = (): JSX.Element => {
  const [showExportModal, setShowExportModal] = useState(false);
  const { units } = useUsers();

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  return (
    <>
      <SubHeader title="Inventory" dropdown="custom" />
      <Box gap="large" pad={{ top: 'medium' }} flex>
        <InventoryTable />
      </Box>

      <Box width="medium" pad={{ top: 'small' }} align="start" gap="small" direction='row'>
        <Box>
          <StyledButton
            label="Export"
            onClick={() => downloadExcel(units)}
          />
        </Box>
      </Box>
      {showExportModal && (
        <ExportModal setShow={setShowExportModal} type="inventory" />
      )}
    </>
  );
};

export default Inventory;
