import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Button
} from 'grommet';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { BackButton } from '../../components';
import Progress from '../../components/Cards/Progress';

interface InfoCardProps {
  header: React.ReactNode;
  body: React.ReactNode;
}

// should these components be seperated into more files?
const User = () => (
  <>
    <Heading margin="none" level="4">
      Name
    </Heading>
    <Text>Customer</Text>
    <Text>Address</Text>
    <Text>Phone number</Text>
    <Text>Email</Text>
  </>
);

const DetailsCard = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const history = useHistory();

  return (
    <Card fill>
      <CardHeader pad="small" background="brandAccent" justify="center">
        <Heading margin="none" level="4">
          Order Details
        </Heading>
      </CardHeader>
      <CardBody pad="medium" background="white" gap="medium">
        <Text>Order #: 123</Text>
        <Box>
          <Text>Unit #: 12</Text>
          <Text>Size: 9 x 12 ft</Text>
        </Box>
        <Box>
          <Text>Rate: $80/mo</Text>
          <Text>Lease: 6 months</Text>
        </Box>
        <Box>
          <Button
            alignSelf="center"
            label="VIEW INVOICE"
            secondary
            size="medium"
            onClick={() => history.push(`/orders/${orderId}/invoice`)}
          />
          {/* should the link be /invoice/id or /id/invoice? */}
        </Box>
        <Box>
          <User />
        </Box>
        <Box>
          <User />
        </Box>
        <Box flex />
      </CardBody>
    </Card>
  );
};

const InfoCard = ({ header, body }: InfoCardProps) => (
  <Card fill pad="medium" gap="small" background="light-1">
    <CardHeader>
      <Heading margin="none" level="5">
        {header}
      </Heading>
    </CardHeader>
    <CardBody>{body}</CardBody>
  </Card>
);

const OrderDetails = (): JSX.Element => (
  <>
    <BackButton title="All Orders" path="/orders" />
    <Box pad={{ horizontal: 'medium', vertical: 'xsmall' }}>
      <Grid
        columns={['flex', 'flex', '1/4']}
        rows={['flex', 'medium', 'small']}
        gap="medium"
        areas={[
          { name: 'progress', start: [0, 0], end: [1, 0] },
          { name: 'details', start: [2, 0], end: [2, 2] },
          { name: 'map', start: [0, 1], end: [1, 1] },
          { name: 'info1', start: [0, 2], end: [0, 2] },
          { name: 'info2', start: [1, 2], end: [1, 2] },
        ]}
      >
        <Box gridArea="progress">
          <Progress orderType="Delivery" />
        </Box>
        <Box gridArea="map" background="accent-3">
          map
        </Box>
        <Box gridArea="details">
          <DetailsCard />
        </Box>
        <Box gridArea="info1">
          <InfoCard
            header="Pick Up/Delivery Info"
            body={
              <>
                <Text>Gate Code: 1234#</Text>
                <Text>Parking: Spots marked as &quot;guest&quot;</Text>
                <Text>Notes: I have a dolly to load things</Text>
              </>
            }
          />
        </Box>
        <Box gridArea="info2">
          <InfoCard
            header="Rental Info"
            body={
              <>
                <Text>Unit Number: 12</Text>
                <Text>SqFt: 108 sqft</Text>
                <Text>Dimensions: 9 x 12</Text>
                <Text>Rate: $80/mo</Text>
                <Text>Discounts: None</Text>
              </>
            }
          />
        </Box>
      </Grid>
    </Box>
  </>
);

export default OrderDetails;
