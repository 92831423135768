/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUnit = /* GraphQL */ `query GetUnit($id: ID!) {
  getUnit(id: $id) {
    id
    facilityID
    size
    usage
    price
    tenantID
    tenant {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    sitelinkUnitID
    sitelinkUnit {
      Ret_Code
      UnitTypeID
      sTypeName
      iDefLeaseNum
      UnitID
      sUnitName
      dcWidth
      dcLength
      dcStdRate
      bRented
      bRentable
      sUnitDesc
      bWaitingListReserved
      DefaultCoverageID
      SiteID
      dcBoardRate
      dcPreferredRate
      dcPushRate
      dcPushRate_NotRounded
      dcStdSecDep
      dcStdWeeklyRate
      dcWebRate
      iDaysVacant
      iFloor
      sLocationCode
      bClimate
      bInside
      bPower
      bAlarm
      dMovedOut
      bCorporate
      dDeleted
      sUnitNote
      dcTax1Rate
      dcTax2Rate
      dcMapTop
      dcMapLeft
      dcMapTheta
      bMapReversWL
      iEntryLoc
      iDoorType
      iADA
      bMobile
      dcRM_RoundTo
      bServiceRequired
      bExcludeFromWebsite
      iPreferredChannelType
      bPreferredIsPushRate
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUnitQueryVariables, APITypes.GetUnitQuery>;
export const listUnits = /* GraphQL */ `query ListUnits(
  $filter: ModelUnitFilterInput
  $limit: Int
  $nextToken: String
) {
  listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      facilityID
      size
      usage
      price
      tenantID
      tenant {
        items {
          id
          facilityID
          name
          email
          phone
          unitID
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          licenseNumber
          licenseState
          orders {
            nextToken
            __typename
          }
          boxes {
            nextToken
            __typename
          }
          plan {
            minStay
            typeOfPlan
            size
            price
            __typename
          }
          ledgerIDs
          tenantID
          accessCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      boxes {
        items {
          id
          tenantID
          facilityID
          unitID
          description
          status
          photo
          location
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sitelinkUnitID
      sitelinkUnit {
        Ret_Code
        UnitTypeID
        sTypeName
        iDefLeaseNum
        UnitID
        sUnitName
        dcWidth
        dcLength
        dcStdRate
        bRented
        bRentable
        sUnitDesc
        bWaitingListReserved
        DefaultCoverageID
        SiteID
        dcBoardRate
        dcPreferredRate
        dcPushRate
        dcPushRate_NotRounded
        dcStdSecDep
        dcStdWeeklyRate
        dcWebRate
        iDaysVacant
        iFloor
        sLocationCode
        bClimate
        bInside
        bPower
        bAlarm
        dMovedOut
        bCorporate
        dDeleted
        sUnitNote
        dcTax1Rate
        dcTax2Rate
        dcMapTop
        dcMapLeft
        dcMapTheta
        bMapReversWL
        iEntryLoc
        iDoorType
        iADA
        bMobile
        dcRM_RoundTo
        bServiceRequired
        bExcludeFromWebsite
        iPreferredChannelType
        bPreferredIsPushRate
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUnitsQueryVariables, APITypes.ListUnitsQuery>;
export const getAdmin = /* GraphQL */ `query GetAdmin($id: ID!) {
  getAdmin(id: $id) {
    id
    name
    createdAt
    updatedAt
    facility {
      items {
        id
        name
        address
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customers {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        units {
          items {
            id
            facilityID
            size
            usage
            price
            tenantID
            sitelinkUnitID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        drivers {
          items {
            id
            facilityID
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            facilityID
            id
            date
            time
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        movingCosts {
          flatFee
          hourlyFee
          __typename
        }
        createdAt
        updatedAt
        plans {
          items {
            id
            facilityID
            type
            minStay
            photo
            size
            price
            ft
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAdminQueryVariables, APITypes.GetAdminQuery>;
export const listAdmins = /* GraphQL */ `query ListAdmins(
  $filter: ModelAdminFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      facility {
        items {
          id
          name
          address
          boxes {
            nextToken
            __typename
          }
          customers {
            nextToken
            __typename
          }
          orders {
            nextToken
            __typename
          }
          units {
            nextToken
            __typename
          }
          drivers {
            nextToken
            __typename
          }
          appointments {
            nextToken
            __typename
          }
          movingCosts {
            flatFee
            hourlyFee
            __typename
          }
          createdAt
          updatedAt
          plans {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminsQueryVariables,
  APITypes.ListAdminsQuery
>;
export const getTenant = /* GraphQL */ `query GetTenant($id: ID!) {
  getTenant(id: $id) {
    id
    facilityID
    name
    email
    phone
    unitID
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    licenseNumber
    licenseState
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    plan {
      minStay
      typeOfPlan
      size
      price
      __typename
    }
    ledgerIDs
    tenantID
    accessCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTenantQueryVariables, APITypes.GetTenantQuery>;
export const listTenants = /* GraphQL */ `query ListTenants(
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
) {
  listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      facilityID
      name
      email
      phone
      unitID
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      licenseNumber
      licenseState
      orders {
        items {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      boxes {
        items {
          id
          tenantID
          facilityID
          unitID
          description
          status
          photo
          location
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      plan {
        minStay
        typeOfPlan
        size
        price
        __typename
      }
      ledgerIDs
      tenantID
      accessCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenantsQueryVariables,
  APITypes.ListTenantsQuery
>;
export const getDriver = /* GraphQL */ `query GetDriver($id: ID!) {
  getDriver(id: $id) {
    id
    facilityID
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDriverQueryVariables, APITypes.GetDriverQuery>;
export const listDrivers = /* GraphQL */ `query ListDrivers(
  $filter: ModelDriverFilterInput
  $limit: Int
  $nextToken: String
) {
  listDrivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      facilityID
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDriversQueryVariables,
  APITypes.ListDriversQuery
>;
export const getBox = /* GraphQL */ `query GetBox($id: ID!) {
  getBox(id: $id) {
    id
    tenantID
    facilityID
    unitID
    description
    status
    photo
    location
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBoxQueryVariables, APITypes.GetBoxQuery>;
export const listBoxs = /* GraphQL */ `query ListBoxs($filter: ModelBoxFilterInput, $limit: Int, $nextToken: String) {
  listBoxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantID
      facilityID
      unitID
      description
      status
      photo
      location
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBoxsQueryVariables, APITypes.ListBoxsQuery>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    tenantID
    facilityID
    date
    time
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    jobType
    status
    driver
    needHelp
    needPack
    moverOption
    orderItems
    movingTime
    movingFee
    isInitialOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantID
      facilityID
      date
      time
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      jobType
      status
      driver
      needHelp
      needPack
      moverOption
      orderItems
      movingTime
      movingFee
      isInitialOrder
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const getAppointment = /* GraphQL */ `query GetAppointment($id: ID!) {
  getAppointment(id: $id) {
    facilityID
    id
    order {
      id
      tenantID
      facilityID
      date
      time
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      jobType
      status
      driver
      needHelp
      needPack
      moverOption
      orderItems
      movingTime
      movingFee
      isInitialOrder
      createdAt
      updatedAt
      __typename
    }
    date
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppointmentQueryVariables,
  APITypes.GetAppointmentQuery
>;
export const listAppointments = /* GraphQL */ `query ListAppointments(
  $filter: ModelAppointmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      facilityID
      id
      order {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      date
      time
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppointmentsQueryVariables,
  APITypes.ListAppointmentsQuery
>;
export const unitsByFacility = /* GraphQL */ `query UnitsByFacility(
  $facilityID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelUnitFilterInput
  $limit: Int
  $nextToken: String
) {
  unitsByFacility(
    facilityID: $facilityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      facilityID
      size
      usage
      price
      tenantID
      tenant {
        items {
          id
          facilityID
          name
          email
          phone
          unitID
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          licenseNumber
          licenseState
          orders {
            nextToken
            __typename
          }
          boxes {
            nextToken
            __typename
          }
          plan {
            minStay
            typeOfPlan
            size
            price
            __typename
          }
          ledgerIDs
          tenantID
          accessCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      boxes {
        items {
          id
          tenantID
          facilityID
          unitID
          description
          status
          photo
          location
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sitelinkUnitID
      sitelinkUnit {
        Ret_Code
        UnitTypeID
        sTypeName
        iDefLeaseNum
        UnitID
        sUnitName
        dcWidth
        dcLength
        dcStdRate
        bRented
        bRentable
        sUnitDesc
        bWaitingListReserved
        DefaultCoverageID
        SiteID
        dcBoardRate
        dcPreferredRate
        dcPushRate
        dcPushRate_NotRounded
        dcStdSecDep
        dcStdWeeklyRate
        dcWebRate
        iDaysVacant
        iFloor
        sLocationCode
        bClimate
        bInside
        bPower
        bAlarm
        dMovedOut
        bCorporate
        dDeleted
        sUnitNote
        dcTax1Rate
        dcTax2Rate
        dcMapTop
        dcMapLeft
        dcMapTheta
        bMapReversWL
        iEntryLoc
        iDoorType
        iADA
        bMobile
        dcRM_RoundTo
        bServiceRequired
        bExcludeFromWebsite
        iPreferredChannelType
        bPreferredIsPushRate
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UnitsByFacilityQueryVariables,
  APITypes.UnitsByFacilityQuery
>;
export const tenantByUnit = /* GraphQL */ `query TenantByUnit(
  $unitID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
) {
  tenantByUnit(
    unitID: $unitID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      facilityID
      name
      email
      phone
      unitID
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      licenseNumber
      licenseState
      orders {
        items {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      boxes {
        items {
          id
          tenantID
          facilityID
          unitID
          description
          status
          photo
          location
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      plan {
        minStay
        typeOfPlan
        size
        price
        __typename
      }
      ledgerIDs
      tenantID
      accessCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenantByUnitQueryVariables,
  APITypes.TenantByUnitQuery
>;
export const tenantByEmail = /* GraphQL */ `query TenantByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
) {
  tenantByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      facilityID
      name
      email
      phone
      unitID
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      licenseNumber
      licenseState
      orders {
        items {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      boxes {
        items {
          id
          tenantID
          facilityID
          unitID
          description
          status
          photo
          location
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      plan {
        minStay
        typeOfPlan
        size
        price
        __typename
      }
      ledgerIDs
      tenantID
      accessCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenantByEmailQueryVariables,
  APITypes.TenantByEmailQuery
>;
export const tenantsByFacility = /* GraphQL */ `query TenantsByFacility(
  $facilityID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
) {
  tenantsByFacility(
    facilityID: $facilityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      facilityID
      name
      email
      phone
      unitID
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      licenseNumber
      licenseState
      orders {
        items {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      boxes {
        items {
          id
          tenantID
          facilityID
          unitID
          description
          status
          photo
          location
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      plan {
        minStay
        typeOfPlan
        size
        price
        __typename
      }
      ledgerIDs
      tenantID
      accessCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TenantsByFacilityQueryVariables,
  APITypes.TenantsByFacilityQuery
>;
export const driversByFacility = /* GraphQL */ `query DriversByFacility(
  $facilityID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelDriverFilterInput
  $limit: Int
  $nextToken: String
) {
  driversByFacility(
    facilityID: $facilityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      facilityID
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DriversByFacilityQueryVariables,
  APITypes.DriversByFacilityQuery
>;
export const boxesByTenant = /* GraphQL */ `query BoxesByTenant(
  $tenantID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelBoxFilterInput
  $limit: Int
  $nextToken: String
) {
  boxesByTenant(
    tenantID: $tenantID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantID
      facilityID
      unitID
      description
      status
      photo
      location
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BoxesByTenantQueryVariables,
  APITypes.BoxesByTenantQuery
>;
export const boxesByFacility = /* GraphQL */ `query BoxesByFacility(
  $facilityID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelBoxFilterInput
  $limit: Int
  $nextToken: String
) {
  boxesByFacility(
    facilityID: $facilityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantID
      facilityID
      unitID
      description
      status
      photo
      location
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BoxesByFacilityQueryVariables,
  APITypes.BoxesByFacilityQuery
>;
export const boxesByUnit = /* GraphQL */ `query BoxesByUnit(
  $unitID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelBoxFilterInput
  $limit: Int
  $nextToken: String
) {
  boxesByUnit(
    unitID: $unitID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantID
      facilityID
      unitID
      description
      status
      photo
      location
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BoxesByUnitQueryVariables,
  APITypes.BoxesByUnitQuery
>;
export const ordersByTenant = /* GraphQL */ `query OrdersByTenant(
  $tenantID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByTenant(
    tenantID: $tenantID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantID
      facilityID
      date
      time
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      jobType
      status
      driver
      needHelp
      needPack
      moverOption
      orderItems
      movingTime
      movingFee
      isInitialOrder
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrdersByTenantQueryVariables,
  APITypes.OrdersByTenantQuery
>;
export const ordersbyFacility = /* GraphQL */ `query OrdersbyFacility(
  $facilityID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersbyFacility(
    facilityID: $facilityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantID
      facilityID
      date
      time
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      jobType
      status
      driver
      needHelp
      needPack
      moverOption
      orderItems
      movingTime
      movingFee
      isInitialOrder
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrdersbyFacilityQueryVariables,
  APITypes.OrdersbyFacilityQuery
>;
export const appointmentsByFacility = /* GraphQL */ `query AppointmentsByFacility(
  $facilityID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelAppointmentFilterInput
  $limit: Int
  $nextToken: String
) {
  appointmentsByFacility(
    facilityID: $facilityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      facilityID
      id
      order {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      date
      time
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AppointmentsByFacilityQueryVariables,
  APITypes.AppointmentsByFacilityQuery
>;
export const getFacility = /* GraphQL */ `query GetFacility($id: ID!) {
  getFacility(id: $id) {
    id
    name
    address
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    customers {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    units {
      items {
        id
        facilityID
        size
        usage
        price
        tenantID
        tenant {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sitelinkUnitID
        sitelinkUnit {
          Ret_Code
          UnitTypeID
          sTypeName
          iDefLeaseNum
          UnitID
          sUnitName
          dcWidth
          dcLength
          dcStdRate
          bRented
          bRentable
          sUnitDesc
          bWaitingListReserved
          DefaultCoverageID
          SiteID
          dcBoardRate
          dcPreferredRate
          dcPushRate
          dcPushRate_NotRounded
          dcStdSecDep
          dcStdWeeklyRate
          dcWebRate
          iDaysVacant
          iFloor
          sLocationCode
          bClimate
          bInside
          bPower
          bAlarm
          dMovedOut
          bCorporate
          dDeleted
          sUnitNote
          dcTax1Rate
          dcTax2Rate
          dcMapTop
          dcMapLeft
          dcMapTheta
          bMapReversWL
          iEntryLoc
          iDoorType
          iADA
          bMobile
          dcRM_RoundTo
          bServiceRequired
          bExcludeFromWebsite
          iPreferredChannelType
          bPreferredIsPushRate
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    drivers {
      items {
        id
        facilityID
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    appointments {
      items {
        facilityID
        id
        order {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        date
        time
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    movingCosts {
      flatFee
      hourlyFee
      __typename
    }
    createdAt
    updatedAt
    plans {
      items {
        id
        facilityID
        type
        minStay
        photo
        size
        price
        ft
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFacilityQueryVariables,
  APITypes.GetFacilityQuery
>;
export const listFacilitys = /* GraphQL */ `query ListFacilitys(
  $filter: ModelFacilityFilterInput
  $limit: Int
  $nextToken: String
) {
  listFacilitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      address
      boxes {
        items {
          id
          tenantID
          facilityID
          unitID
          description
          status
          photo
          location
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customers {
        items {
          id
          facilityID
          name
          email
          phone
          unitID
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          licenseNumber
          licenseState
          orders {
            nextToken
            __typename
          }
          boxes {
            nextToken
            __typename
          }
          plan {
            minStay
            typeOfPlan
            size
            price
            __typename
          }
          ledgerIDs
          tenantID
          accessCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      orders {
        items {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      units {
        items {
          id
          facilityID
          size
          usage
          price
          tenantID
          tenant {
            nextToken
            __typename
          }
          boxes {
            nextToken
            __typename
          }
          sitelinkUnitID
          sitelinkUnit {
            Ret_Code
            UnitTypeID
            sTypeName
            iDefLeaseNum
            UnitID
            sUnitName
            dcWidth
            dcLength
            dcStdRate
            bRented
            bRentable
            sUnitDesc
            bWaitingListReserved
            DefaultCoverageID
            SiteID
            dcBoardRate
            dcPreferredRate
            dcPushRate
            dcPushRate_NotRounded
            dcStdSecDep
            dcStdWeeklyRate
            dcWebRate
            iDaysVacant
            iFloor
            sLocationCode
            bClimate
            bInside
            bPower
            bAlarm
            dMovedOut
            bCorporate
            dDeleted
            sUnitNote
            dcTax1Rate
            dcTax2Rate
            dcMapTop
            dcMapLeft
            dcMapTheta
            bMapReversWL
            iEntryLoc
            iDoorType
            iADA
            bMobile
            dcRM_RoundTo
            bServiceRequired
            bExcludeFromWebsite
            iPreferredChannelType
            bPreferredIsPushRate
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      drivers {
        items {
          id
          facilityID
          name
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      appointments {
        items {
          facilityID
          id
          order {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          date
          time
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      movingCosts {
        flatFee
        hourlyFee
        __typename
      }
      createdAt
      updatedAt
      plans {
        items {
          id
          facilityID
          type
          minStay
          photo
          size
          price
          ft
          description
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFacilitysQueryVariables,
  APITypes.ListFacilitysQuery
>;
export const getStoragePlan = /* GraphQL */ `query GetStoragePlan($id: ID!) {
  getStoragePlan(id: $id) {
    id
    facilityID
    type
    minStay
    photo
    size
    price
    ft
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStoragePlanQueryVariables,
  APITypes.GetStoragePlanQuery
>;
export const listStoragePlans = /* GraphQL */ `query ListStoragePlans(
  $filter: ModelStoragePlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listStoragePlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      facilityID
      type
      minStay
      photo
      size
      price
      ft
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStoragePlansQueryVariables,
  APITypes.ListStoragePlansQuery
>;
export const plansByFacility = /* GraphQL */ `query PlansByFacility(
  $facilityID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelStoragePlanFilterInput
  $limit: Int
  $nextToken: String
) {
  plansByFacility(
    facilityID: $facilityID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      facilityID
      type
      minStay
      photo
      size
      price
      ft
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlansByFacilityQueryVariables,
  APITypes.PlansByFacilityQuery
>;
