import { Avatar, Box, Card, Text } from 'grommet';
import { Deliver, Group } from 'grommet-icons';
import React, { useState } from 'react';

import { User } from '../../context/model/types';
import UserModal from '../Modals/UserModal/UserModal';

interface CardProps {
  user: User;
  type: 'customer' | 'driver';
}

export const UserCard = ({ user, type }: CardProps): JSX.Element => {
  const [userModal, setUserModal] = useState(false);

  return (
    <>
      <Card
        fill
        round="xsmall"
        background="light-1"
        onClick={() => setUserModal(true)}
        hoverIndicator={{ elevation: 'large' }}
        focusIndicator={false}
      >
        <Box fill direction="row" align="center" gap="medium" pad="small">
          <Avatar
            size="large"
            src="https://1.gravatar.com/userimage/212100380/bfb34d02a1e842372821360907aa9583?size=256"
          />

          <Box flex justify="between" direction="row" align="stretch">
            <Box>
              <Box direction="row" align="center" gap="xsmall">
                <Text truncate>{`${user.name}`}</Text>
                {type === 'customer' ? (
                  <Group size="xsmall" />
                ) : (
                  <Deliver size="xsmall" />
                )}
              </Box>
              <Box>
                <Text size="xsmall" color="dark-3">
                  Email: {user.id}
                </Text>
                <Text size="xsmall" color="dark-3">
                  Phone: {user.phone}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
      {userModal && (
        <UserModal user={user} setShow={setUserModal} type={type} />
      )}
    </>
  );
};

export default UserCard;
