import { Box, Text } from 'grommet';
import React from 'react';

import CustomFilter from './CustomFilter';
import SearchBar from './SearchBar';
import SortDropdown from './SortDropdown';

const SubHeader = ({
  title,
  icon,
  dropdown = 'simple',
}: {
  title: string;
  icon?: JSX.Element;
  dropdown?: 'simple' | 'custom';
}): JSX.Element => (
  <Box
    direction="row"
    justify="between"
    width="100%"
    align="center"
    pad={{ left: 'small', bottom: 'small' }} // only added this because subheader and cards don't align in figma
  >
    <Box direction="column" gap="small">
      <Box direction="row" align="center" gap="small">
        <Text size="xlarge">{title}</Text>
        {icon}
      </Box>
      {/* dropdown === 'simple' ? <SortDropdown /> : <CustomFilter /> */}
    </Box>
    <Box width="medium">
      {/* <SearchBar /> */} 
    </Box>
  </Box>
);

export default SubHeader;
