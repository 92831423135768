import { Box, Text, Grid, RadioButtonGroup, CheckBox } from 'grommet';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import Modal from './Modal';
import StyledButton from '../StyledButton';

const inventoryCheckboxes = [
  'Unit Number',
  'Unit Size',
  'Unit Price',
  'Customer Name',
  'Arrears',
  'Lease Length',
  'Expiration',
  'Level',
  'Row',
  'Transit Status',
];

const ordersCheckboxes = [
  'Status',
  'Order Type',
  'Order ID',
  'Est. Completion',
  'Customer Name',
  'Customer Contact',
  'Driver',
  'Driver Name',
  'Driver Contact',
  'Unit #',
];

// TODO: exportmodal probably will just use a hook or context to get data itself (not props)
const ExportModal = ({
  setShow,
  type,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'inventory' | 'orders';
}): JSX.Element => {
  const [format, setFormat] = useState<string>();
  const [checked, setChecked] = useState<string[]>([]);
  const [checkboxes, setCheckboxes] = useState<string[]>([]);

  useEffect(() => {
    if (type === 'inventory') {
      setCheckboxes(inventoryCheckboxes);
    } else if (type === 'orders') {
      setCheckboxes(ordersCheckboxes);
    }
  }, [type]);

  const exportData = () => {
    if (!format) {
      toast.error('Please select an export format');
      return;
    }
    if (!checked) {
      toast.error('Please select at least one column to export');
      return;
    }
    // actually do data download here
    toast.success('Data export success!');
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (event.target.checked) {
      setChecked([...checked, value]);
    } else {
      setChecked(checked.filter(item => item !== value));
    }
  };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChecked(checkboxes);
    } else {
      setChecked([]);
    }
  };

  // TODO: probably want to change the radio and checkbox styles
  return (
    <Modal
      setShow={setShow}
      header={
        type === 'inventory'
          ? 'Export Your Valet Storage Leases Easily to Sitelink'
          : "(ASK) not sure what header to put here cuz it's not for sitelink?" // TODO: ask design, albert, bobby what should be here
      }
      headerBackground="none"
    >
      <Box gap="large" pad={{ horizontal: 'large' }}>
        <Box gap="large">
          <Box gap="medium">
            <Text weight={500}>Export As:</Text>
            <RadioButtonGroup
              name="export format"
              options={['CSV', 'Excel']}
              gap="small"
              value={format}
              onChange={event => setFormat(event.target.value)}
            />
          </Box>
          <Box gap="medium">
            <Text weight={500}>Select Columns:</Text>
            <Grid columns={{ count: 'fill', size: 'small' }} gap="small">
              <CheckBox
                checked={checked.length === checkboxes.length}
                indeterminate={
                  checked.length > 0 && checked.length < checkboxes.length
                }
                label="All"
                onChange={handleCheckAll}
              />
              {checkboxes.map(item => (
                <CheckBox
                  key={item}
                  checked={checked.includes(item)}
                  label={item}
                  onChange={e => handleCheck(e, item)}
                />
              ))}
            </Grid>
          </Box>
        </Box>
        <Box direction="row" justify="center" gap="xlarge">
          <StyledButton label="Export" onClick={exportData} />
        </Box>
      </Box>
    </Modal>
  );
};

export default ExportModal;
