import { ThemeType, grommet } from 'grommet';

const theme: ThemeType = {
  ...grommet,
  global: {
    font: {
      family: 'Roboto, Ubuntu, Arial, sans-serif',
      size: '18px',
      height: '20px',
    },
    colors: {
      brand: '#261136',
      brandAccent: '#7B1FA2',
      focus: '#7B1FA2',
      'light-5': '#C4C4C4',
    },
    input: {
      font: {
        weight: 'lighter',
      },
    },
    // TODO: might need to change values based on new card
    // this is for the card grid
    // hope it doesn't break things...
    // i think xsmall is bigger than small sometimes lmao
    breakpoints: {
      small: {
        value: 650,
      },
      medium: {
        value: 1100,
      },
      large: {
        value: 3000,
      },
    },
  },
  icon: {
    size: {
      xsmall: '16px',
    },
  },
  carousel: {
    icons: {
      color: 'brandAccent',
    },
    disabled: {
      icons: {
        color: 'grey',
      },
    },
  },
  pagination: {
    button: {
      active: {
        background: {
          color: 'brandAccent',
        },
      },
    },
  },
};

export default theme;
