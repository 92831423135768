/* eslint-disable */

import { Grommet, Box } from 'grommet';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Landing from './website/Landing.js'
import {NavBar} from './website/NavBar.js'
import About from './website/About.js'
import Download from './website/Download.js'
import Faq from './website/FAQ.js'
import Contact from './website/Contact.js'
import Privacy from './website/Privacy'
import { Header, Footer } from './components';
import { AuthWrapper } from './context/auth';
import { UsersWrapper } from './context/users';
import {
  Dashboard,
  Orders,
  Customers,
  Invoices,
  Inventory,
  Drivers,
  OrderDetails,
  Billing,
  CalendarPage,
} from './pages';
import FullPageInvoice from './pages/Invoices/FullPageInvoice';
import theme from './utils/theme';

const Routes = (): JSX.Element => (
  <Switch>
    <Route exact path="/Admin">
      {/* TODO: login page? below is temp redirect */}
      {/* loggedIn ? <Redirect to="dashboard"/> : <login or home page/> */}
      <Redirect to="/Admin/dashboard" />
    </Route>
    <Route path="/Admin/dashboard">
      <Dashboard />
    </Route>
    <Route exact path="/Admin/orders">
      <Orders />
    </Route>
    <Route path="/Admin/customers">
      <Customers />
    </Route>
    <Route path="/Admin/invoices">
      <Invoices />
    </Route>
    <Route path="/Admin/inventory">
      <Inventory />
    </Route>
    <Route path="/Admin/drivers">
      <Drivers />
    </Route>
    <Route path="/Admin/calendar">
      <CalendarPage />
    </Route>
    <Route path="/Admin/settings">
      <Billing />
    </Route>
    <Route exact path="/orders/:orderId">
      <OrderDetails />
    </Route>
    <Route exact path="/orders/:orderId/invoice">
      <FullPageInvoice />
    </Route>
  </Switch>
);

const App = (): JSX.Element => (
  <AuthWrapper>
  <Grommet full theme={theme}>
    <Toaster />
    <UsersWrapper>
      <Router>
        <Box height={{ min: '100%' }} width={{ min: 'fit-content' }}>
          <Header />
          <Box
            flex
            background="light-3"
            as="main"
            pad={{ vertical: 'medium', horizontal: 'medium' }}
          >
            <Routes />
          </Box>
        </Box>
      </Router>
    </UsersWrapper>
  </Grommet>
  </AuthWrapper>
);

const App2 = (): JSX.Element => (
<div className="App" style={{ overflowX: 'hidden' }}>
{/*<Helmet>
      <title>Bins Storage</title>
      <meta name="description" content="Store your items and have them delivered, all without lifting a finger. Discover a valet storage facility near you." />
</Helmet>*/}
    <Router>
      <NavBar />
      <Switch>
        <Route exact path='/'>
          <Landing />
        </Route>
        <Route path='/Admin'>
          <App/>
        </Route>
        <Route path='/About'>
          <About />
        </Route>
        <Route path='/FAQ'>
          <Faq />
        </Route>
        <Route path='/Contact'>
          <Contact />
        </Route>
        <Route path='/Privacy'>
           <Privacy />
        </Route>
        <Route path='/Download'>
           <Download />
        </Route>
      </Switch>
    </Router>
    <Footer />
  </div>
);

export default App2;
