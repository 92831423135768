import { Text, Box, Button } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface BackButtonProps {
  title: string;
  path: string;
}

const BackButton = ({ title, path }: BackButtonProps): JSX.Element => {
  const history = useHistory();

  return (
    <Box align="start" pad={{ top: 'none', bottom: 'small' }}>
      <Button
        label={
          <Box>
            <Text size="small">{`Back to ${title}`}</Text>
          </Box>
        }
        plain
        icon={<LinkPrevious size="small" />}
        size="small"
        gap="xsmall"
        onClick={() => history.push(path)}
      />
    </Box>
  );
};

export default BackButton;
