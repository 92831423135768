import { API, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect } from 'react';

import { GetTenants } from './controller/controller';
import { User, Driver, OrderType, Unit, BoxType, Facility, Admin, Appointment } from './model/types';
import { listDrivers, listOrders, getAdmin, unitsByFacility, boxesByFacility, appointmentsByFacility } from '../graphql/queries';
import { GetAdminQueryResult, Facilities } from '../graphql/types';
import { GetAvailableUnits } from '../utils/sitelink/sitelink';
import { getAppointments } from '../utils/getAppointments';
import { SitelinkTableEntry, SitelinkUnit } from '../utils/sitelink/types';

type UsersContextType = {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  drivers: Driver[];
  setDrivers: React.Dispatch<React.SetStateAction<Driver[]>>;
  orders: OrderType[];
  setOrders: React.Dispatch<React.SetStateAction<OrderType[]>>;
  facilityInfo: Facility | undefined;
  setFacility: React.Dispatch<React.SetStateAction<Facility | undefined>>;
  admin: Admin[];
  setAdmin: React.Dispatch<React.SetStateAction<Admin[]>>;
  units: Unit[];
  setUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  // Units == DynamoDB, sitelinkUnits == Sitelink DB
  sitelinkUnits: SitelinkTableEntry<SitelinkUnit>[],
  setSitelinkUnits: React.Dispatch<React.SetStateAction<SitelinkTableEntry<SitelinkUnit>[]>>
  boxes: BoxType[];
  setBoxes: React.Dispatch<React.SetStateAction<BoxType[]>>;
  appointments: Appointment[]; // Deprecated
  setAppointments: React.Dispatch<React.SetStateAction<Appointment[]>>; // Deprecated
  appointments2: any[];
  setAppointments2: React.Dispatch<React.SetStateAction<any[]>>;
};

const UsersContext = createContext<UsersContextType>(undefined!);

// Convert AWS Facility into AdminApp Facility type
const appsyncFacilityToFacilityType = (input: Facilities): Facility |  undefined => {
  if (!input) {
    return undefined
  }
  const facility = input.items[0]
  if (facility === null || !facility.name || !facility.address || !facility.movingCosts?.flatFee || !facility.movingCosts.hourlyFee) {
    return undefined
  }

  return {
    id: facility.id,
    name: facility.name,
    address: facility.address,
    movingCosts: {
      flatFee: facility.movingCosts?.flatFee,
      hourlyFee: facility.movingCosts?.hourlyFee,
    }
  }
}

export const UsersWrapper: React.FC = ({ children }) => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [drivers, setDrivers] = React.useState<Driver[]>([]);
  const [orders, setOrders] = React.useState<OrderType[]>([]);
  const [facilityInfo, setFacility] = React.useState<Facility | undefined>(undefined);
  const [admin, setAdmin] = React.useState<Admin[]>([]);
  const [units, setUnits] = React.useState<Unit[]>([]);
  const [boxes, setBoxes] = React.useState<BoxType[]>([]);
  const [appointments, setAppointments] = React.useState<Appointment[]>([]); // Deprecated
  const [appointments2, setAppointments2] = React.useState<any[]>([]);
  const [sitelinkUnits, setSitelinkUnits] = React.useState<SitelinkTableEntry<SitelinkUnit>[]>([]);
  const value = ' ';

  // TODO(sitelink): input should not be any type.
  // TODO(cleanup): maybe this should be an async fun and not littered with .then().then().then()... chains, but rather awaits and resolveAll's
  async function fetchFacility(awsFacilities: Facilities) {
    const facility = appsyncFacilityToFacilityType(awsFacilities)
    if (!facility) {
      alert('No facility found for the admin. Please try again.');
      return;
    }
    setFacility(facility);
    const facilityID = facility.id;
    setUsers(await GetTenants(facilityID))
    const orderList = API.graphql({query: listOrders, variables: {filter: {facilityID: {eq: facilityID}}}}) as Promise<any>;
    orderList.then(({ data: { listOrders: { items } } } ) => setOrders(items));
    const driverList = API.graphql({query: listDrivers, variables: {filter: {facilityID: {eq: facilityID}}}}) as Promise<any>;
    driverList.then(({ data: { listDrivers: { items } } } ) => setDrivers(items));
    // eslint-disable-next-line
    const boxList = API.graphql({query: boxesByFacility, variables: {facilityID: facilityID, limit: 10000}}) as Promise<any>;
    boxList.then(({ data: { boxesByFacility: { items } } } ) => setBoxes(items));
    // eslint-disable-next-line
    const unitList = API.graphql({query: unitsByFacility, variables: {facilityID: facilityID}}) as Promise<any>;
    unitList.then(({ data: { unitsByFacility: { items } } } ) => setUnits(items));
    // eslint-disable-next-line
    const appointmentList = API.graphql({query: appointmentsByFacility, variables: {facilityID: facilityID}}) as Promise<any>; // Deprecated
    appointmentList.then(({ data: { appointmentsByFacility: { items } } } ) => {setAppointments(items); console.log("Fetched Appointments:", items);}); // Deprecated

    const groupAppointmentsByDate = (appointments) => {
      return appointments.reduce((acc, appointment) => {
        const date = appointment.date; // Expecting appointment.date to be in YYYY-MM-DD format
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(appointment);
        return acc;
      }, {});
    };

    const fetchAppointments = async () => {
      try {
        const data = await getAppointments(); // Modify this based on your API logic if necessary
        const appointmentsData = JSON.parse(data.body); // Ensure parsed data if necessary
        const filteredAppointmentsData = appointmentsData.filter((appointment: any) => 
          appointment.facilityID === facilityID
        );
        const groupedAppointments = groupAppointmentsByDate(filteredAppointmentsData);

        setAppointments2(groupedAppointments);
        // setAppointments(groupedAppointments); // Update state with grouped appointments
      } catch (err) {
        console.log(err);
      }
    };
    await fetchAppointments(); // Execute the fetchAppointments function

    try {
      // Fetch sitelink units. The current "shortcut solution" uses a field in the Unit schema type which holds the sitelink unit id.
      const fetchedSitelinkUnits = await GetAvailableUnits();
      // alert(fetchedSitelinkUnits.map(unit => unit.Table.UnitID));
      setSitelinkUnits(fetchedSitelinkUnits);
    } catch (e: unknown) {
      alert('Unable to fetch sitelink units. Please try again.');
      setSitelinkUnits([]);
    }
  }

  useEffect(() => {
    const fetchAdmin = async () => {
    try {
      const currentUserInfo = await Auth.currentAuthenticatedUser();
      const userEmail = currentUserInfo.attributes.email;
      // TODO(typing): should the values here be narrowed because they are ? types?
      const adminInfo = await API.graphql({query: getAdmin, variables: {id: userEmail}}) as GetAdminQueryResult;
      const maybeFacilityInfo = adminInfo.data.getAdmin?.facility;
      console.log(adminInfo)
      console.log(maybeFacilityInfo)
      fetchFacility(maybeFacilityInfo);
    } catch (error) {
      console.error('Error fetching admin:', error);
      // Handle error, maybe show a message to the user
    }
  };
    fetchAdmin();
    // eslint-disable-next-line
    }, [value]
  ); 

  const defaultValue = { users, setUsers, drivers, setDrivers, orders, setOrders, facilityInfo, setFacility, admin, setAdmin, units, setUnits, boxes, setBoxes, appointments, setAppointments, sitelinkUnits, setSitelinkUnits, appointments2, setAppointments2 };

  return (
    <UsersContext.Provider value={defaultValue}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = (): UsersContextType => useContext(UsersContext);
