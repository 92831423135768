export async function getAppointments() {
    const response = await fetch("https://yhc75qpqp6.execute-api.us-east-2.amazonaws.com/prod/appointments");
    
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    
    const data = await response.json();
    return data;
}

export async function createAppointment(appointmentData: any) {
    const formattedData = {
        body: JSON.stringify(appointmentData) // Wrap the JSON string in a body key
    };

    const response = await fetch("https://yhc75qpqp6.execute-api.us-east-2.amazonaws.com/prod/create-apppointment", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData), // Send the formatted data as the body
    });

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
}
