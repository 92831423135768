import { Box, Text, Grid } from 'grommet';
import React from 'react';

import Modal from './Modal';
import { useUsers } from '../../context/users';

const AppointmentsModal = ({
  setShow,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {

  const today = new Date();

  const getToday = () => {
    let month = `${(today.getMonth() + 1)}`;
    let day = `${today.getDate()}`;
    const year = today.getFullYear();
    if (month.length < 2)
          month = `0${month}`;
    if (day.length < 2) 
          day = `0${day}`;
  
      return [year, month, day].join('-');
  }

  const { appointments } = useUsers();
  const filterData = appointments.filter(row => row.date >= getToday());

  return (
    <Modal
      setShow={setShow}
      header="Upcoming Appointments"
    >
   <Grid>
    {filterData.map(item => (
          <Box>
            <Text>{item.date}, {item.time}</Text>
          </Box>
    ))}
    </Grid>
    </Modal>
  );
};


export default AppointmentsModal;
