import { Box, Text } from 'grommet';
import React from 'react';

const DriverOrders = (): JSX.Element => (
  <Box gap="medium">
    <Box gap="small">
      <Text weight="bold" style={{ textDecoration: 'underline' }}>
        Order History:
      </Text>
      <Box gap="medium">
        {Array.from({ length: 20 }, () => ({
          name: 'customer name',
          type: 'pickup/delivery',
          date: '0/0',
        })).map(item => (
          <>
            <Text weight="bold">
              Customer:{' '}
              <Text weight="normal" color="dark-3">
                {item.name}
              </Text>
            </Text>
            <Text weight="bold">
              Date:{' '}
              <Text weight="normal" color="dark-3">
                {item.date}
              </Text>
            </Text>
            <Text weight="bold">
              Order Type:{' '}
              <Text weight="normal" color="dark-3">
                {item.type}
              </Text>
            </Text>
          </>
        ))}
      </Box>
    </Box>
  </Box>
);

export default DriverOrders;
