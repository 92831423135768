/* eslint-disable */
import React from 'react';
import { Box } from 'grommet';

import './styles/styles.css'
import graphic from '../assets/images/how-bins-works-3.png'
import graphic2 from '../assets/images/QR Code.png'
import graphic3 from '../assets/images/arrow.png'

const Download = () => (
  <body>
    <section class="download" id="download">
      <div class="rowContainer">
        <div class="smallcontainer">
          <h2 class="c">Get the Bins Storage App</h2>
          <p class="a">Take care of all your storage needs, all from you mobile device.</p>
          <div className="imageContainer">
            <img src={graphic3}/>
          </div>
        </div>
        <div class="QRCodeContainer">
          <center><img src={graphic2}/></center>
        </div>
      </div>
      <div class="graphicontainer">
      <center><img src={graphic} width= "55%"/></center>
      </div>
     </section> 
  </body>
)

export default Download
