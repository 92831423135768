import { Avatar, Box, Card, Text } from 'grommet';
import { Deliver, Group } from 'grommet-icons';
import React, { useState } from 'react';

import { Driver } from '../../context/model/types';
import AppointmentsModal from '../Modals/AppointmentsModal';
import StyledButton from '../StyledButton';

interface CardProps {
  user: Driver;
  type: 'customer' | 'driver';
}

export const DriverCard = ({ user, type }: CardProps): JSX.Element => {

  const [showAppointmentsModal, setShowAppointmentsModal] = useState(false);

return(
    <>
      <Card
        fill
        round="xsmall"
        background="light-1"
        hoverIndicator={{ elevation: 'large' }}
        focusIndicator={false}
      >
        <Box fill direction="row" align="center" gap="medium" pad="small">
          <Avatar
            size="large"
            src="https://1.gravatar.com/userimage/212100380/bfb34d02a1e842372821360907aa9583?size=256"
          />

          <Box flex justify="between" direction="row" align="stretch">
            <Box>
              <Box direction="row" align="center" gap="xsmall">
                <Text>{`${user.name}`}</Text>
                {type === 'customer' ? (
                  <Group size="xsmall" />
                ) : (
                  <Deliver size="xsmall" />
                )}
              </Box>
            </Box>
          </Box>
          <StyledButton label="View"  onClick={() => setShowAppointmentsModal(true)}
/>
        </Box>        
      </Card>
      {showAppointmentsModal && (
        <AppointmentsModal setShow={setShowAppointmentsModal}/>
      )}
    </>
  )
      };

export default DriverCard;
