import { Box, Text, TextInput } from 'grommet';
import { Alert, Erase } from 'grommet-icons';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

import Modal from './Modal';
import StyledButton from '../StyledButton';

const DeleteUserModal = ({
  setShow,
  setUserShow,
  name,
  userType,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setUserShow: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  userType: 'Customer' | 'Driver';
}): JSX.Element => {
  const [input, setInput] = useState('');
  const [deleted, setDeleted] = useState(false);

  const confirmDelete = () => {
    if (input === name) {
      // console.log('delete successful');
      // use generalized notification
      toast.success('User Deleted');
      setDeleted(true);
    } else {
      toast.error(`Failed to delete ${name}`, { id: 'delete user' });
      //   console.log('delete failed');
    }
  };

  return (
    <Modal
      setShow={setShow}
      header={
        deleted ? '' : `Are you sure you want to delete ${name}, (${userType})?`
      }
      headerIcon={deleted ? <Erase size="large" /> : <Alert size="large" />}
    >
      {deleted ? (
        <Box gap="medium" pad="large" align="center">
          <Text>{`${name}, ${userType}, has been permanently deleted.`}</Text>
          <StyledButton
            label="Return to Customers"
            onClick={() => {
              setShow(false);
              setUserShow(false);
            }}
          />
        </Box>
      ) : (
        <Box gap="large" pad={{ horizontal: 'large' }}>
          <Box gap="medium">
            <Text>
              Please type the {userType}
              &apos;s full name to confirm deletion.
              <br />
              Once deleted, this action cannot be undone.
            </Text>
            <TextInput
              placeholder={name}
              value={input}
              onChange={event => setInput(event.target.value)}
            />
          </Box>
          <Box direction="row" justify="center" gap="xlarge">
            <StyledButton label="Cancel" onClick={() => setShow(false)} />
            <StyledButton label="Confirm" onClick={confirmDelete} />
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default DeleteUserModal;
