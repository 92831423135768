import {
  AmplifyAuthenticator,
  AmplifyAuthContainer,
  AmplifySignIn,
  AmplifySignUp,
} from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Box, Text, Select, TextInput, Spinner } from 'grommet';

const Login: React.FC = () => {
  const handleSignUp = async (formData: any) => {
    // console.log('FORM DATA HERE', formData);
    const param = {
      ...formData,
      attributes: {
        ...formData.attributes,
      },
    };
    const data = await Auth.signUp(param);
    return data;
  };

  const [adminCode, setAdminCode] = React.useState("");

  return (
    /*  <Box>
      <Button
        onClick={() =>  setLoggedIn(false)}
        label={
          <Box>
          <Text size='small'>{isLoggedIn.toString()}</Text>
          </Box>
        }/>
    </Box>  */
  <Box>
  { adminCode !== "richmond" ? (
    <Box align='center' justify='center' height='small'>
      <Box width='small'>
      <Text>Enter Admin Code:</Text>
      <TextInput
          onChange={event => setAdminCode(event.target.value)}
      />
    </Box>
    </Box>
  ) : (
   <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          handleSignUp={handleSignUp}
          formFields={[
            {
              type: 'name',
              label: 'Full Name *', // lmao need to add an * even though it's required
              placeholder: 'Enter your full name',
              inputProps: { required: true },
            },
            { type: 'email' },
            { type: 'password' },
            { type: 'phone_number' },
          ]}
        />

        <AmplifySignIn
          headerText="Sign in to Bins Storage"
          slot="sign-in"
          usernameAlias="email"
          hideSignUp
          // handleSubmit={() => setLoggedIn(true)}
          formFields={[
            {
              type: 'email',
              inputProps: { required: true, autocomplete: 'username' },
            },
            {
              type: 'password',
              inputProps: { required: true, autocomplete: 'new-password' },
            },
          ]}
        />
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  ) }
  </Box>
  );
};

export default Login;
