import { Card, DataTable, ThemeContext, Text, Button } from 'grommet';
import React, { useState } from 'react';

import { useUsers } from '../../context/users';
import ItemModal from '../Modals/ItemModal';
import UnitModal from '../Modals/UnitModal';

const renderCell = (text: string | number | undefined) => (
  <Text size="small" textAlign="center">
    {typeof text === 'undefined' ? '--' : text}
  </Text>
);

const InventoryTable = (): JSX.Element => {

  const [showItemModal, setShowItemModal] = useState(false);
  const [showUnitModal, setShowUnitModal] = useState(false);
  const [unitID, setUnitID] = useState(0);

  const showModal = (id: number) => {
    setShowItemModal(true);
    setUnitID(id);
  } 

  const showUnit = (id: number) => {
    setShowUnitModal(true);
    setUnitID(id);
  } 

  const renderItems = (id: number) => (
    <Button size = "small"
            primary label="View" 
            margin = "medium" 
            onClick={() => showModal(id)} 
    />
  );

  const renderEditButton = (id: number) => (
    <Button size = "small"
            primary label="Edit" 
            margin = "medium" 
            onClick={() => showUnit(id)} 
    />
  );

  const { units } = useUsers();

  const columns = [
    {
      sortable: true,
      property: 'unit',
      header: 'Unit ID',
      size: 'xxsmall',
      render: ({ id }: { id: number }) => renderCell(id),
    },
    {
      sortable: true,
      property: 'unitName',
      header: 'Unit Name',
      size: 'xxsmall',
      render: ({ sitelinkUnit }: { sitelinkUnit: { sUnitName: string } }) => renderCell(sitelinkUnit.sUnitName)

    },
    {
      property: 'Tenant',
      header: 'Tenant',
      size: 'xsmall',
      render: ({ tenantID }: { tenantID: string }) =>renderCell(tenantID),
    },
    {
      property: 'unitSize',
      header: 'Unit Size',
      size: 'xxsmall',
      render: ({ size }: { size: string }) =>
        renderCell(`${size} sqft`),
    },
    {
      property: 'usage',
      header: 'Usage',
      size: 'xxsmall',
      render: ({ usage }: { usage: string }) => renderCell(usage),
    },
    {
      property: 'price',
      header: 'Price',
      size: 'xxsmall',
      render: ({ price }: { price: string }) => renderCell(price),
    },
    {
      property: 'items',
      header: 'Items',
      size: 'xxsmall',
      render: ({ id }: { id: number }) => renderItems(id),
    },
    {
      property: 'edit',
      size: 'xxsmall',
      render: ({ id }: { id: number }) => renderEditButton(id),
    },
  ];

  return (
    <ThemeContext.Extend
      value={{
        dataTable: {
          header: {
            border: {
              color: 'light-5',
              side: 'bottom',
            },
            font: {
              weight: '500',
              size: 'small',
              textAlign: 'center',
            },
          },
        },
      }}
    >
      <Card background="light-1" height={{ max: 'large' }} overflow="auto">
        <DataTable
          data={units}
          columns={columns}
          // size="medium" // PROBLEM: grommet "size" forces all columns to be same width. With resize would be fluid but cannot use size
          pad={{ header: { horizontal: 'xxsmall' }, body: 'xxsmall' }}
          border={{ body: { color: 'light-5' } }}
          sortable
        />
      </Card>
      {showItemModal && (
        <ItemModal
          setShow={setShowItemModal}
          unitID={unitID}
        />
      )}
      {showUnitModal && (
        <UnitModal
          setShow={setShowUnitModal}
          unitID={unitID}
        />
      )}
    </ThemeContext.Extend>
  );
};

export default InventoryTable;
