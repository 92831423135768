import { Box } from 'grommet';
import React, { useState } from 'react';
import * as XLSX from "xlsx";

import {
  SubHeader,
  OrdersTable,
  StyledButton,
  ExportModal,
} from '../../components';
import { useUsers } from '../../context/users';

const Orders = (): JSX.Element => {
  const [showExportModal, setShowExportModal] = useState(false);
  const { orders } = useUsers();

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  return (
    <>
      <SubHeader title="Orders" dropdown="custom" />
      <Box gap="large" pad={{ top: 'medium' }}>
        <OrdersTable type="Active Orders" />
        <OrdersTable type="Closed Orders" />
      </Box>

      <Box width="small" pad={{ top: 'small', left: 'small' }} align="start">
        <Box pad={{ left: 'xsmall' }}>
          <StyledButton
            label="Export"
            onClick={() => downloadExcel(orders)}
          />
        </Box>
      </Box>
      {showExportModal && (
        <ExportModal setShow={setShowExportModal} type="orders" />
      )}
    </>
  );
};

export default Orders;
