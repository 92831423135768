/* eslint-disable */
import React from 'react';

import './styles/styles.css'
import background from '../assets/images/trees-background.png'
import appStoreButton from '../assets/images/app-store-button.png'
import googleStoreButton from '../assets/images/google-play-button.png'

export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailSent: false,
            name: '',
            email: '',
            message: '',
        }
    }

    onFormSubmit = () => {
        console.log('Form Submitted');
    }

    onNameChange = (event) => {
        this.setState({name: event.target.value});
    }

    onEmailChange = (event) => {
        this.setState({email: event.target.value})
    }

    onMessageChange = (event) => {
        this.setState({message: event.target.value});
    }

    // Note on class(className) vs. id - class can be applied to multiple elements
    // whereas id should be a unique identifier for only one element.
    // This is primarily for styling purposes (in a css file)
    render() {
        return (
            <div>
                <div class="container">
                    <img src={background} width="100%"/>
                    <div class="centered">
                        <h1>Contact Us</h1>
                    </div>
                </div>
                <div class="rowContainer">
                    <div class="containerHalf">
                        <h2 class="c">Need Help?</h2>
                        <p class="a">All inquiries, including issues / deleting your account, managing orders, and more, email us at:</p>
                        <p class="a" >help@bins-storage.com</p>
                    </div>

                    <div class="containerHalf">
                        <h2 class="c">Storage Facilities – Become a Partner</h2>
                        <p class="a">We are offering storage companies the opportunity to partner with us and join the platform. Gain more customers through a new channels and fill unwanted vacancies.</p>
                        <center><a href="https://forms.gle/88mtu637wESKE5dJ9" class="button">Join Us</a></center>
                    </div>
                </div>
            </div>
        );
    }
}

/* This is a contact us form
<div class="formcontainer">
    <form id='contact-form' onSubmit={this.onFormSubmit} method='POST'>
        <div className='contact-form-inputs'>
            <label htmlFor="inputName">Name</label>
            <input id='inputName' type="text" value={this.state.name} onChange={this.onNameChange}/>
        </div>
        <div className='contact-form-inputs'>
            <label htmlFor='inputEmail'>Email</label>
            <input id='inputEmail' type='email' value={this.state.email} onChange={this.onEmailChange}/>
        </div>
        <div className='contact-form-inputs'>
            <label htmlFor='messageContent'>Message</label>
            <textarea id='messageContent' rows='5' value={this.state.message} onChange={this.onMessageChange} />
        </div>
        <button type='submit'>Submit</button>
    </form>
</div>
*/
