import {
  Box,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Stack,
} from 'grommet';
import React from 'react';
import { useParams } from 'react-router-dom';

import { BackButton } from '../../components';

const FullPageInvoice = (): JSX.Element => {
  const { orderId } = useParams<{ orderId: string }>();

  //   TODO: also check if invoice for this id exists (in addition to order details)
  //   if(...){
  //   return (
  //     <>
  //       <BackButton title="All Orders" path="/orders" />
  //       <Box align="center">
  //         <Heading level={3}>
  //           Sorry, this order doesn&apos;t seem to exist
  //         </Heading>
  //         <Text>some 😢 image here</Text>
  //       </Box>
  //     </>
  //   );
  //   }

  return (
    <>
      <BackButton title="Order Details" path={`/orders/${orderId}`} />

      <Card pad="small" background="light-1">
        <CardHeader pad="small">
          <Stack anchor="bottom" fill>
            <Text size="large" color="brand">
              Bins
              <br />
              Storage
            </Text>
            <Text size="xxlarge" weight="normal" alignSelf="center">
              A1 Storage Facility
            </Text>
          </Stack>
        </CardHeader>
        <CardBody pad={{ horizontal: 'medium', vertical: 'small' }} gap="small">
          <Box direction="row" gap="xsmall">
            <Text weight="bold">Customer:</Text>
            <Text> John Doe</Text>
          </Box>
          <Box direction="row" gap="xsmall">
            <Text weight="bold">Address: </Text>
            <Text>1874 Everdly Dr. Los Angeles, 90046</Text>
          </Box>
          <Heading level="1">OTHER INVOICE INFO...</Heading>
        </CardBody>
      </Card>
    </>
  );
};

export default FullPageInvoice;
