import { Box, Card, Text, Tip } from 'grommet';
import { Deliver } from 'grommet-icons';
import React, { useState } from 'react';

import InvoiceModal from '../Modals/InvoiceModal';

// TODO: invoice card needs to accept invoice object and pass down to modal
export const InvoiceCard = (): JSX.Element => {
  const [invoiceModal, setInvoiceModal] = useState(false);
  return (
    <>
      <Card
        fill
        round="xsmall"
        background="light-1"
        onClick={() => setInvoiceModal(true)}
        hoverIndicator={{ elevation: 'large' }}
        focusIndicator={false}
      >
        <Box pad={{ horizontal: 'small', top: 'small', bottom: 'xsmall' }}>
          <Box gap="xsmall">
            <Box direction="row" justify="between">
              <Text weight="bold" size="small">
                #ordernum
              </Text>
              <Text weight="bold" size="small">
                USD $9000.01
              </Text>
            </Box>

            <Box>
              <Text size="xsmall" color="dark-3" truncate>
                Customer: Trevor Trinh
              </Text>
              <Text size="xsmall" color="dark-3">
                Order Date: October 29, 2021
              </Text>
            </Box>
          </Box>

          <Box align="end">
            {/* TODO: conditional message and icon based off state */}
            <Tip
              plain
              dropProps={{
                align: { left: 'right', top: 'bottom' },
                elevation: 'large',
                round: 'xsmall',
                background: { color: 'light-6' },
              }}
              content={
                <Box pad="xsmall">
                  <Text size="small">Delivery in Progress</Text>
                </Box>
              }
            >
              <Box
                // expands the hoverable area to make it easier to see tip
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  zIndex: 1,
                  padding: '5px 10px',
                  margin: '-5px -10px',
                }}
              >
                <Deliver size="xsmall" color="accent-4" />
              </Box>
            </Tip>
          </Box>
        </Box>
      </Card>
      {invoiceModal && <InvoiceModal setShow={setInvoiceModal} />}
    </>
  );
};

export default InvoiceCard;
