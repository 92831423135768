import { Box, Text, TextInput, Select } from 'grommet';
import React, { useState } from 'react';
import Modal from './Modal';
import StyledButton from '../StyledButton';
import { createAppointment } from '../../utils/getAppointments';
import { useUsers } from '../../context/users';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

const timeOptions = [
  "12:00am", "12:30am", "1:00am", "1:30am", "2:00am", "2:30am", "3:00am", "3:30am",
  "4:00am", "4:30am", "5:00am", "5:30am", "6:00am", "6:30am", "7:00am", "7:30am",
  "8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am",
  "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm",
  "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm", "6:30pm", "7:00pm", "7:30pm",
  "8:00pm", "8:30pm", "9:00pm", "9:30pm", "10:00pm", "10:30pm", "11:00pm", "11:30pm"
];

const CreateAppointmentModal = ({
  setShow,
  date,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  date: string;
}): JSX.Element => {
  const [title, setTitle] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');

  const randomID = uuidv4();
  const { facilityInfo } = useUsers();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  const isEndTimeAfterStartTime = (start: string, end: string): boolean => {
    const startIndex = timeOptions.indexOf(start);
    const endIndex = timeOptions.indexOf(end);
    return endIndex > startIndex;
  };
  
  const handleBlockTime = async () => {
    const appointmentData = {
      id: randomID,
      date: formatDate(date),
      driver: "",
      facilityID: facilityInfo?.id,
      orderID: "",
      tenantID: "",
      time: `${startTime}-${endTime}`,
      type: title.trim() === "" ? "Block" : title.trim(),
  };

    try {
        const result = await createAppointment(appointmentData);
        console.log('Appointment created:', result);
    } catch (error) {
        console.error('Error creating appointment:', error);
    }
  }

  const confirmSubmit = () => {
    if (startTime && endTime) {
      if (isEndTimeAfterStartTime(startTime, endTime)) {
        handleBlockTime();
        toast.success('Appointment created successfully!');
        setShow(false);
      } else {
        toast.error('End time must be after start time.');
      }
    } else {
      toast.error('Please fill out the required fields.');
    }
  };

  return (
    <Modal setShow={setShow} header="Add a Block">
      <Box gap="medium" pad={{ horizontal: 'large' }}>
        <Box direction="column" gap="medium">
          {/* Display the date */}
          <Text>Date: {date}</Text>

          {/* Title input */}
          <TextInput
            placeholder="Title (optional)"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />

          {/* Start time dropdown */}
          <Select
            placeholder="Start"
            options={timeOptions}
            value={startTime}
            onChange={({ option }) => setStartTime(option)}
          />

          {/* End time dropdown */}
          <Select
            placeholder="End"
            options={timeOptions}
            value={endTime}
            onChange={({ option }) => setEndTime(option)}
          />
        </Box>

        {/* Submit button */}
        <Box direction="row" justify="center">
          <StyledButton label="Submit" onClick={confirmSubmit} />
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateAppointmentModal;
