import { Group } from 'grommet-icons';
import React from 'react';

import { PaginatedCardGrid, SubHeader, UserCard } from '../../components';
import { useUsers, User } from '../../context/users';

const Customers = (): JSX.Element => {
  const { users } = useUsers();
  return (
    <>
      <SubHeader title="Customers" icon={<Group />} />
      <PaginatedCardGrid
        data={users}
        card={(user: User) => (
          <UserCard key={user.id} user={user} type="customer" />
        )}
      />
    </>
  );
};

export default Customers;
