/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUnit = /* GraphQL */ `mutation CreateUnit(
  $input: CreateUnitInput!
  $condition: ModelUnitConditionInput
) {
  createUnit(input: $input, condition: $condition) {
    id
    facilityID
    size
    usage
    price
    tenantID
    tenant {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    sitelinkUnitID
    sitelinkUnit {
      Ret_Code
      UnitTypeID
      sTypeName
      iDefLeaseNum
      UnitID
      sUnitName
      dcWidth
      dcLength
      dcStdRate
      bRented
      bRentable
      sUnitDesc
      bWaitingListReserved
      DefaultCoverageID
      SiteID
      dcBoardRate
      dcPreferredRate
      dcPushRate
      dcPushRate_NotRounded
      dcStdSecDep
      dcStdWeeklyRate
      dcWebRate
      iDaysVacant
      iFloor
      sLocationCode
      bClimate
      bInside
      bPower
      bAlarm
      dMovedOut
      bCorporate
      dDeleted
      sUnitNote
      dcTax1Rate
      dcTax2Rate
      dcMapTop
      dcMapLeft
      dcMapTheta
      bMapReversWL
      iEntryLoc
      iDoorType
      iADA
      bMobile
      dcRM_RoundTo
      bServiceRequired
      bExcludeFromWebsite
      iPreferredChannelType
      bPreferredIsPushRate
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUnitMutationVariables,
  APITypes.CreateUnitMutation
>;
export const updateUnit = /* GraphQL */ `mutation UpdateUnit(
  $input: UpdateUnitInput!
  $condition: ModelUnitConditionInput
) {
  updateUnit(input: $input, condition: $condition) {
    id
    facilityID
    size
    usage
    price
    tenantID
    tenant {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    sitelinkUnitID
    sitelinkUnit {
      Ret_Code
      UnitTypeID
      sTypeName
      iDefLeaseNum
      UnitID
      sUnitName
      dcWidth
      dcLength
      dcStdRate
      bRented
      bRentable
      sUnitDesc
      bWaitingListReserved
      DefaultCoverageID
      SiteID
      dcBoardRate
      dcPreferredRate
      dcPushRate
      dcPushRate_NotRounded
      dcStdSecDep
      dcStdWeeklyRate
      dcWebRate
      iDaysVacant
      iFloor
      sLocationCode
      bClimate
      bInside
      bPower
      bAlarm
      dMovedOut
      bCorporate
      dDeleted
      sUnitNote
      dcTax1Rate
      dcTax2Rate
      dcMapTop
      dcMapLeft
      dcMapTheta
      bMapReversWL
      iEntryLoc
      iDoorType
      iADA
      bMobile
      dcRM_RoundTo
      bServiceRequired
      bExcludeFromWebsite
      iPreferredChannelType
      bPreferredIsPushRate
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUnitMutationVariables,
  APITypes.UpdateUnitMutation
>;
export const deleteUnit = /* GraphQL */ `mutation DeleteUnit(
  $input: DeleteUnitInput!
  $condition: ModelUnitConditionInput
) {
  deleteUnit(input: $input, condition: $condition) {
    id
    facilityID
    size
    usage
    price
    tenantID
    tenant {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    sitelinkUnitID
    sitelinkUnit {
      Ret_Code
      UnitTypeID
      sTypeName
      iDefLeaseNum
      UnitID
      sUnitName
      dcWidth
      dcLength
      dcStdRate
      bRented
      bRentable
      sUnitDesc
      bWaitingListReserved
      DefaultCoverageID
      SiteID
      dcBoardRate
      dcPreferredRate
      dcPushRate
      dcPushRate_NotRounded
      dcStdSecDep
      dcStdWeeklyRate
      dcWebRate
      iDaysVacant
      iFloor
      sLocationCode
      bClimate
      bInside
      bPower
      bAlarm
      dMovedOut
      bCorporate
      dDeleted
      sUnitNote
      dcTax1Rate
      dcTax2Rate
      dcMapTop
      dcMapLeft
      dcMapTheta
      bMapReversWL
      iEntryLoc
      iDoorType
      iADA
      bMobile
      dcRM_RoundTo
      bServiceRequired
      bExcludeFromWebsite
      iPreferredChannelType
      bPreferredIsPushRate
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUnitMutationVariables,
  APITypes.DeleteUnitMutation
>;
export const createAdmin = /* GraphQL */ `mutation CreateAdmin(
  $input: CreateAdminInput!
  $condition: ModelAdminConditionInput
) {
  createAdmin(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    facility {
      items {
        id
        name
        address
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customers {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        units {
          items {
            id
            facilityID
            size
            usage
            price
            tenantID
            sitelinkUnitID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        drivers {
          items {
            id
            facilityID
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            facilityID
            id
            date
            time
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        movingCosts {
          flatFee
          hourlyFee
          __typename
        }
        createdAt
        updatedAt
        plans {
          items {
            id
            facilityID
            type
            minStay
            photo
            size
            price
            ft
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminMutationVariables,
  APITypes.CreateAdminMutation
>;
export const updateAdmin = /* GraphQL */ `mutation UpdateAdmin(
  $input: UpdateAdminInput!
  $condition: ModelAdminConditionInput
) {
  updateAdmin(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    facility {
      items {
        id
        name
        address
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customers {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        units {
          items {
            id
            facilityID
            size
            usage
            price
            tenantID
            sitelinkUnitID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        drivers {
          items {
            id
            facilityID
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            facilityID
            id
            date
            time
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        movingCosts {
          flatFee
          hourlyFee
          __typename
        }
        createdAt
        updatedAt
        plans {
          items {
            id
            facilityID
            type
            minStay
            photo
            size
            price
            ft
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminMutationVariables,
  APITypes.UpdateAdminMutation
>;
export const deleteAdmin = /* GraphQL */ `mutation DeleteAdmin(
  $input: DeleteAdminInput!
  $condition: ModelAdminConditionInput
) {
  deleteAdmin(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    facility {
      items {
        id
        name
        address
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customers {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        units {
          items {
            id
            facilityID
            size
            usage
            price
            tenantID
            sitelinkUnitID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        drivers {
          items {
            id
            facilityID
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        appointments {
          items {
            facilityID
            id
            date
            time
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        movingCosts {
          flatFee
          hourlyFee
          __typename
        }
        createdAt
        updatedAt
        plans {
          items {
            id
            facilityID
            type
            minStay
            photo
            size
            price
            ft
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminMutationVariables,
  APITypes.DeleteAdminMutation
>;
export const createTenant = /* GraphQL */ `mutation CreateTenant(
  $input: CreateTenantInput!
  $condition: ModelTenantConditionInput
) {
  createTenant(input: $input, condition: $condition) {
    id
    facilityID
    name
    email
    phone
    unitID
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    licenseNumber
    licenseState
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    plan {
      minStay
      typeOfPlan
      size
      price
      __typename
    }
    ledgerIDs
    tenantID
    accessCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantMutationVariables,
  APITypes.CreateTenantMutation
>;
export const updateTenant = /* GraphQL */ `mutation UpdateTenant(
  $input: UpdateTenantInput!
  $condition: ModelTenantConditionInput
) {
  updateTenant(input: $input, condition: $condition) {
    id
    facilityID
    name
    email
    phone
    unitID
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    licenseNumber
    licenseState
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    plan {
      minStay
      typeOfPlan
      size
      price
      __typename
    }
    ledgerIDs
    tenantID
    accessCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTenantMutationVariables,
  APITypes.UpdateTenantMutation
>;
export const deleteTenant = /* GraphQL */ `mutation DeleteTenant(
  $input: DeleteTenantInput!
  $condition: ModelTenantConditionInput
) {
  deleteTenant(input: $input, condition: $condition) {
    id
    facilityID
    name
    email
    phone
    unitID
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    licenseNumber
    licenseState
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    plan {
      minStay
      typeOfPlan
      size
      price
      __typename
    }
    ledgerIDs
    tenantID
    accessCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTenantMutationVariables,
  APITypes.DeleteTenantMutation
>;
export const createDriver = /* GraphQL */ `mutation CreateDriver(
  $input: CreateDriverInput!
  $condition: ModelDriverConditionInput
) {
  createDriver(input: $input, condition: $condition) {
    id
    facilityID
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDriverMutationVariables,
  APITypes.CreateDriverMutation
>;
export const updateDriver = /* GraphQL */ `mutation UpdateDriver(
  $input: UpdateDriverInput!
  $condition: ModelDriverConditionInput
) {
  updateDriver(input: $input, condition: $condition) {
    id
    facilityID
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDriverMutationVariables,
  APITypes.UpdateDriverMutation
>;
export const deleteDriver = /* GraphQL */ `mutation DeleteDriver(
  $input: DeleteDriverInput!
  $condition: ModelDriverConditionInput
) {
  deleteDriver(input: $input, condition: $condition) {
    id
    facilityID
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDriverMutationVariables,
  APITypes.DeleteDriverMutation
>;
export const createBox = /* GraphQL */ `mutation CreateBox(
  $input: CreateBoxInput!
  $condition: ModelBoxConditionInput
) {
  createBox(input: $input, condition: $condition) {
    id
    tenantID
    facilityID
    unitID
    description
    status
    photo
    location
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBoxMutationVariables,
  APITypes.CreateBoxMutation
>;
export const updateBox = /* GraphQL */ `mutation UpdateBox(
  $input: UpdateBoxInput!
  $condition: ModelBoxConditionInput
) {
  updateBox(input: $input, condition: $condition) {
    id
    tenantID
    facilityID
    unitID
    description
    status
    photo
    location
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBoxMutationVariables,
  APITypes.UpdateBoxMutation
>;
export const deleteBox = /* GraphQL */ `mutation DeleteBox(
  $input: DeleteBoxInput!
  $condition: ModelBoxConditionInput
) {
  deleteBox(input: $input, condition: $condition) {
    id
    tenantID
    facilityID
    unitID
    description
    status
    photo
    location
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBoxMutationVariables,
  APITypes.DeleteBoxMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    tenantID
    facilityID
    date
    time
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    jobType
    status
    driver
    needHelp
    needPack
    moverOption
    orderItems
    movingTime
    movingFee
    isInitialOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    tenantID
    facilityID
    date
    time
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    jobType
    status
    driver
    needHelp
    needPack
    moverOption
    orderItems
    movingTime
    movingFee
    isInitialOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    tenantID
    facilityID
    date
    time
    address {
      tenantID
      addressLine1
      addressLine2
      city
      state
      zip
      specialInstructions
      building
      parking
      __typename
    }
    jobType
    status
    driver
    needHelp
    needPack
    moverOption
    orderItems
    movingTime
    movingFee
    isInitialOrder
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createAppointment = /* GraphQL */ `mutation CreateAppointment(
  $input: CreateAppointmentInput!
  $condition: ModelAppointmentConditionInput
) {
  createAppointment(input: $input, condition: $condition) {
    facilityID
    id
    order {
      id
      tenantID
      facilityID
      date
      time
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      jobType
      status
      driver
      needHelp
      needPack
      moverOption
      orderItems
      movingTime
      movingFee
      isInitialOrder
      createdAt
      updatedAt
      __typename
    }
    date
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAppointmentMutationVariables,
  APITypes.CreateAppointmentMutation
>;
export const updateAppointment = /* GraphQL */ `mutation UpdateAppointment(
  $input: UpdateAppointmentInput!
  $condition: ModelAppointmentConditionInput
) {
  updateAppointment(input: $input, condition: $condition) {
    facilityID
    id
    order {
      id
      tenantID
      facilityID
      date
      time
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      jobType
      status
      driver
      needHelp
      needPack
      moverOption
      orderItems
      movingTime
      movingFee
      isInitialOrder
      createdAt
      updatedAt
      __typename
    }
    date
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAppointmentMutationVariables,
  APITypes.UpdateAppointmentMutation
>;
export const deleteAppointment = /* GraphQL */ `mutation DeleteAppointment(
  $input: DeleteAppointmentInput!
  $condition: ModelAppointmentConditionInput
) {
  deleteAppointment(input: $input, condition: $condition) {
    facilityID
    id
    order {
      id
      tenantID
      facilityID
      date
      time
      address {
        tenantID
        addressLine1
        addressLine2
        city
        state
        zip
        specialInstructions
        building
        parking
        __typename
      }
      jobType
      status
      driver
      needHelp
      needPack
      moverOption
      orderItems
      movingTime
      movingFee
      isInitialOrder
      createdAt
      updatedAt
      __typename
    }
    date
    time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAppointmentMutationVariables,
  APITypes.DeleteAppointmentMutation
>;
export const createFacility = /* GraphQL */ `mutation CreateFacility(
  $input: CreateFacilityInput!
  $condition: ModelFacilityConditionInput
) {
  createFacility(input: $input, condition: $condition) {
    id
    name
    address
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    customers {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    units {
      items {
        id
        facilityID
        size
        usage
        price
        tenantID
        tenant {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sitelinkUnitID
        sitelinkUnit {
          Ret_Code
          UnitTypeID
          sTypeName
          iDefLeaseNum
          UnitID
          sUnitName
          dcWidth
          dcLength
          dcStdRate
          bRented
          bRentable
          sUnitDesc
          bWaitingListReserved
          DefaultCoverageID
          SiteID
          dcBoardRate
          dcPreferredRate
          dcPushRate
          dcPushRate_NotRounded
          dcStdSecDep
          dcStdWeeklyRate
          dcWebRate
          iDaysVacant
          iFloor
          sLocationCode
          bClimate
          bInside
          bPower
          bAlarm
          dMovedOut
          bCorporate
          dDeleted
          sUnitNote
          dcTax1Rate
          dcTax2Rate
          dcMapTop
          dcMapLeft
          dcMapTheta
          bMapReversWL
          iEntryLoc
          iDoorType
          iADA
          bMobile
          dcRM_RoundTo
          bServiceRequired
          bExcludeFromWebsite
          iPreferredChannelType
          bPreferredIsPushRate
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    drivers {
      items {
        id
        facilityID
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    appointments {
      items {
        facilityID
        id
        order {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        date
        time
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    movingCosts {
      flatFee
      hourlyFee
      __typename
    }
    createdAt
    updatedAt
    plans {
      items {
        id
        facilityID
        type
        minStay
        photo
        size
        price
        ft
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFacilityMutationVariables,
  APITypes.CreateFacilityMutation
>;
export const updateFacility = /* GraphQL */ `mutation UpdateFacility(
  $input: UpdateFacilityInput!
  $condition: ModelFacilityConditionInput
) {
  updateFacility(input: $input, condition: $condition) {
    id
    name
    address
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    customers {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    units {
      items {
        id
        facilityID
        size
        usage
        price
        tenantID
        tenant {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sitelinkUnitID
        sitelinkUnit {
          Ret_Code
          UnitTypeID
          sTypeName
          iDefLeaseNum
          UnitID
          sUnitName
          dcWidth
          dcLength
          dcStdRate
          bRented
          bRentable
          sUnitDesc
          bWaitingListReserved
          DefaultCoverageID
          SiteID
          dcBoardRate
          dcPreferredRate
          dcPushRate
          dcPushRate_NotRounded
          dcStdSecDep
          dcStdWeeklyRate
          dcWebRate
          iDaysVacant
          iFloor
          sLocationCode
          bClimate
          bInside
          bPower
          bAlarm
          dMovedOut
          bCorporate
          dDeleted
          sUnitNote
          dcTax1Rate
          dcTax2Rate
          dcMapTop
          dcMapLeft
          dcMapTheta
          bMapReversWL
          iEntryLoc
          iDoorType
          iADA
          bMobile
          dcRM_RoundTo
          bServiceRequired
          bExcludeFromWebsite
          iPreferredChannelType
          bPreferredIsPushRate
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    drivers {
      items {
        id
        facilityID
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    appointments {
      items {
        facilityID
        id
        order {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        date
        time
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    movingCosts {
      flatFee
      hourlyFee
      __typename
    }
    createdAt
    updatedAt
    plans {
      items {
        id
        facilityID
        type
        minStay
        photo
        size
        price
        ft
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFacilityMutationVariables,
  APITypes.UpdateFacilityMutation
>;
export const deleteFacility = /* GraphQL */ `mutation DeleteFacility(
  $input: DeleteFacilityInput!
  $condition: ModelFacilityConditionInput
) {
  deleteFacility(input: $input, condition: $condition) {
    id
    name
    address
    boxes {
      items {
        id
        tenantID
        facilityID
        unitID
        description
        status
        photo
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    customers {
      items {
        id
        facilityID
        name
        email
        phone
        unitID
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        licenseNumber
        licenseState
        orders {
          items {
            id
            tenantID
            facilityID
            date
            time
            jobType
            status
            driver
            needHelp
            needPack
            moverOption
            orderItems
            movingTime
            movingFee
            isInitialOrder
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        plan {
          minStay
          typeOfPlan
          size
          price
          __typename
        }
        ledgerIDs
        tenantID
        accessCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    orders {
      items {
        id
        tenantID
        facilityID
        date
        time
        address {
          tenantID
          addressLine1
          addressLine2
          city
          state
          zip
          specialInstructions
          building
          parking
          __typename
        }
        jobType
        status
        driver
        needHelp
        needPack
        moverOption
        orderItems
        movingTime
        movingFee
        isInitialOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    units {
      items {
        id
        facilityID
        size
        usage
        price
        tenantID
        tenant {
          items {
            id
            facilityID
            name
            email
            phone
            unitID
            licenseNumber
            licenseState
            ledgerIDs
            tenantID
            accessCode
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        boxes {
          items {
            id
            tenantID
            facilityID
            unitID
            description
            status
            photo
            location
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sitelinkUnitID
        sitelinkUnit {
          Ret_Code
          UnitTypeID
          sTypeName
          iDefLeaseNum
          UnitID
          sUnitName
          dcWidth
          dcLength
          dcStdRate
          bRented
          bRentable
          sUnitDesc
          bWaitingListReserved
          DefaultCoverageID
          SiteID
          dcBoardRate
          dcPreferredRate
          dcPushRate
          dcPushRate_NotRounded
          dcStdSecDep
          dcStdWeeklyRate
          dcWebRate
          iDaysVacant
          iFloor
          sLocationCode
          bClimate
          bInside
          bPower
          bAlarm
          dMovedOut
          bCorporate
          dDeleted
          sUnitNote
          dcTax1Rate
          dcTax2Rate
          dcMapTop
          dcMapLeft
          dcMapTheta
          bMapReversWL
          iEntryLoc
          iDoorType
          iADA
          bMobile
          dcRM_RoundTo
          bServiceRequired
          bExcludeFromWebsite
          iPreferredChannelType
          bPreferredIsPushRate
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    drivers {
      items {
        id
        facilityID
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    appointments {
      items {
        facilityID
        id
        order {
          id
          tenantID
          facilityID
          date
          time
          address {
            tenantID
            addressLine1
            addressLine2
            city
            state
            zip
            specialInstructions
            building
            parking
            __typename
          }
          jobType
          status
          driver
          needHelp
          needPack
          moverOption
          orderItems
          movingTime
          movingFee
          isInitialOrder
          createdAt
          updatedAt
          __typename
        }
        date
        time
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    movingCosts {
      flatFee
      hourlyFee
      __typename
    }
    createdAt
    updatedAt
    plans {
      items {
        id
        facilityID
        type
        minStay
        photo
        size
        price
        ft
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFacilityMutationVariables,
  APITypes.DeleteFacilityMutation
>;
export const createStoragePlan = /* GraphQL */ `mutation CreateStoragePlan(
  $input: CreateStoragePlanInput!
  $condition: ModelStoragePlanConditionInput
) {
  createStoragePlan(input: $input, condition: $condition) {
    id
    facilityID
    type
    minStay
    photo
    size
    price
    ft
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStoragePlanMutationVariables,
  APITypes.CreateStoragePlanMutation
>;
export const updateStoragePlan = /* GraphQL */ `mutation UpdateStoragePlan(
  $input: UpdateStoragePlanInput!
  $condition: ModelStoragePlanConditionInput
) {
  updateStoragePlan(input: $input, condition: $condition) {
    id
    facilityID
    type
    minStay
    photo
    size
    price
    ft
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStoragePlanMutationVariables,
  APITypes.UpdateStoragePlanMutation
>;
export const deleteStoragePlan = /* GraphQL */ `mutation DeleteStoragePlan(
  $input: DeleteStoragePlanInput!
  $condition: ModelStoragePlanConditionInput
) {
  deleteStoragePlan(input: $input, condition: $condition) {
    id
    facilityID
    type
    minStay
    photo
    size
    price
    ft
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStoragePlanMutationVariables,
  APITypes.DeleteStoragePlanMutation
>;
