/*
Returns an array where contents are of the following shape:
    {
        Table: {
            Ret_Code: 1,
            UnitTypeID: 523,
            sTypeName: 'Drive Up',
            iDefLeaseNum: 1,
            UnitID: 42127,
            sUnitName: 'W',
            dcWidth: '5.0000',
            dcLength: '10.0000',
            bClimate: false,
            dcStdRate: '50.0000',
            bRented: false,
            bInside: false,
            bPower: true,
            bAlarm: true,
            iFloor: 1,
            bWaitingListReserved: false,
            dMovedOut: null,
            bCorporate: null,
            bRentable: true,
            dDeleted: null,
            dcBoardRate: '50.0000',
            sUnitNote: null,
            dcPushRate: '50.0000',
            dcTax1Rate: '0.0000',
            dcTax2Rate: '0.0000',
            sUnitDesc: 'dsds',
            dcStdWeeklyRate: '10.0000',
            dcMapTop: null,
            dcMapLeft: null,
            dcMapTheta: '0.0000',
            bMapReversWL: false,
            iEntryLoc: 2,
            iDoorType: 1,
            iADA: 0,
            dcStdSecDep: '50.0000',
            bMobile: false,
            SiteID: 33707,
            sLocationCode: 'DEMO',
            dcPushRate_NotRounded: '50.0000',
            dcRM_RoundTo: '0.0000',
            bServiceRequired: false,
            iDaysVacant: 189,
            bExcludeFromWebsite: false,
            DefaultCoverageID: -999,
            dcWebRate: '50.0000',
            dcPreferredRate: '50.0000',
            iPreferredChannelType: 0,
            bPreferredIsPushRate: false
        }
    }
 */

import { SitelinkMoveInFailure, SitelinkMoveInResponse, SitelinkMoveInSuccess, SitelinkTableEntry, SitelinkUnit } from "./types";

const testingBasePath = 'https://3h6u0t1vye.execute-api.us-east-1.amazonaws.com/Stage';

// TODO(sitelink): filter out all null values for response in the api call instead
export async function GetAvailableUnits() {
    const query = `${testingBasePath}/GetAvailableUnits?corp_code=TEST&location_code=TEST&api_user=TEST&api_pw=TEST`;
    try {
        const units: SitelinkTableEntry<SitelinkUnit>[] = await fetch(query)
            .then((resp) => resp.json())
            .then((json) => {
                console.log(json)
                if(Array.isArray(json)) {
                    return json as SitelinkTableEntry<SitelinkUnit>[];
                }
                return [];
            })
        return units
    } catch (e: unknown) {
        console.error(e);
        return [];
    }
}

// TODO(bobby): Remove TEST location.
/*
    On success, returns json with the following contents
    {
        RT: {
            Ret_Code: int
            iLeaseNum: int 
        }
    }

    On failure, returns json with the following contents
    {
        RT: {
            Ret_Code: int
            Ret_Msg: string
        }
    }
*/
export async function ExecuteSitelinkMoveIn(
    corp_code, location_code, api_user, api_pw, tenant_id: number, tenant_gate_code, unit_id: number, move_in_date,
    cc_type: number, cc_number: number, cc_cvv: number, cc_expiration, cc_billing_name, cc_billing_address,
    cc_billing_zipcode: number, test_mode): Promise<null | SitelinkMoveInResponse> {
    const query = 
        `${testingBasePath}/MoveIn?corp_code=TEST&location_code=TEST&api_user=TEST&` +
        `api_pw=TEST&tenant_id=${tenant_id}&tenant_gate_code=${tenant_gate_code}&` +
        `unit_id=${unit_id}&move_in_date=${move_in_date}&cc_type=${cc_type}&` +
        `cc_number=${cc_number}&cc_cvv=${cc_cvv}&cc_expiration=${cc_expiration}&` +
        `cc_billing_name=${cc_billing_name}&cc_billing_address=${cc_billing_address}&` +
        `cc_billing_zipcode=${cc_billing_zipcode}&test_mode=${test_mode}`;
    const requestBody = {
        corp_code: corp_code,
        location_code: location_code,
        api_user: api_user,
        api_pw: api_pw,
        tenant_id: tenant_id,
        tenant_gate_code: tenant_gate_code,
        unit_id: unit_id,
        move_in_date: move_in_date,
        cc_type: cc_type,
        cc_number: cc_number,
        cc_cvv: cc_cvv,
        cc_expiration: cc_expiration,
        cc_billing_name: cc_billing_name,
        cc_billing_address: cc_billing_address,
        cc_billing_zipcode: cc_billing_zipcode,
        test_mode: test_mode
    }
    try {
        const test = JSON.stringify(requestBody)
        console.log('successfully stringified')
        const sitelinkMoveInStatus = await fetch(query, {
            method: "POST",
            body: JSON.stringify({requestBody})
        })
            .then(resp => resp.json())
            .then(json => {
                console.log('Sitelink request fired and returned.', json['Ret_Code'])
                // Albert removed [RT] from json['RT']['Ret_Code'] below
                if (json['Ret_Code'] < 0) {
                    return json as SitelinkMoveInFailure
                }
                return json as SitelinkMoveInSuccess
            })
        return sitelinkMoveInStatus
    } catch (e: unknown) {
        console.error(e);
        return null;
    }
    // fetch might need to be a POST
}