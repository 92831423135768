import { Auth, Hub } from 'aws-amplify';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Text, Spinner } from 'grommet';

import Login from '../components/Login';

type User = {
  id: string;
  username: string;
  attributes: {
    email: string;
    phone_number: string;
    'custom:FullName': string;
  };
};

type AuthContextType = {
  user: User | null;
  signOut: () => void;
};

const AuthContext = createContext<AuthContextType>(undefined!);

export const AuthWrapper: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const getUserData = async () => {
    setUser(await Auth.currentUserInfo());
    setIsLoading(false)
  };

  const signOut = async () => {
    await Auth.signOut();
  };

  useEffect(() => {
    setIsLoading(true);
    getUserData();

    const onHubCapsule = (capsule: any) => {
      switch (capsule.payload.event) {
        case 'signIn':
          getUserData();
          break;
        case 'signUp':
          break;
        case 'signOut':
          setUser(null);
          break;
        default:
      }
    };

    Hub.listen('auth', onHubCapsule);
  }, []);

  // useEffect(() => {
  //   console.log('BRUH HERE');
  //   const printInfo = async () => {
  //     const info = await Auth.currentUserInfo();
  //     console.log('current user info', info);
  //   };

  //   printInfo();
  // });

  const defaultValue = { user, signOut };

  if (isLoading) {
    return <Spinner/>;
  }

  if (!user) {
    return <Login />;
  }

  return (
    <AuthContext.Provider value={defaultValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => useContext(AuthContext);