import { Box, Text } from 'grommet';
import React from 'react';

const CustomerOrders = (): JSX.Element => (
  <Box gap="small">
    <Text weight="bold" style={{ textDecoration: 'underline' }}>
      Orders:
    </Text>

    <Box gap="medium">
      {Array.from({ length: 20 }, () => ({
        date: 'datething',
        type: 'pickup/delivery',
        driver: 'binsbros',
      })).map(item => (
        <>
          <Text weight="bold">
            Date:{' '}
            <Text weight="normal" color="dark-3">
              {item.date}
            </Text>
          </Text>
          <Text weight="bold">
            Type:{' '}
            <Text weight="normal" color="dark-3">
              {item.type}
            </Text>
          </Text>
          <Text weight="bold">
            Driver:{' '}
            <Text weight="normal" color="dark-3">
              {item.driver}
            </Text>
          </Text>
        </>
      ))}
    </Box>
  </Box>
);

export default CustomerOrders;
