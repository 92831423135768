import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
// import '@aws-amplify/ui/dist/style.css';

import App2 from './App';
import aws_exports from './aws-exports';
import './index.css';

Amplify.configure(aws_exports);

ReactDOM.render(
  <React.StrictMode>
      <App2 />
  </React.StrictMode>,
  document.getElementById('root'),
);
