import { Box, Button, Text, ThemeContext } from 'grommet';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Modal from './Modal';

interface ModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

// TODO: should accept invoice object from the card
const InvoiceModal = ({ setShow }: ModalProps): JSX.Element => {
  const history = useHistory();

  return (
    <ThemeContext.Extend
      value={{
        button: {
          border: {
            radius: '5px',
          },
        },
      }}
    >
      <Modal
        setShow={setShow}
        width={{ min: 'medium' }}
        headerBackground="none"
        bodyPad={{ bottom: 'medium', top: 'none' }}
        header={
          <>
            <Box fill direction="row" justify="between">
              <Text weight="bold">#ordernum</Text>
              <Text weight="bold">USD $9000.01</Text>
            </Box>
          </>
        }
      >
        <Box gap="medium" pad="none">
          <Box
            overflow={{ vertical: 'auto' }}
            height={{ max: 'medium' }}
            gap="medium"
          >
            <Box direction="row" justify="between">
              <Box gap="small">
                <Text weight="bold">Customer</Text>
                <Text color="dark-3">customerName</Text>
                <Text color="dark-3">
                  123 street <br />
                  address 9001
                </Text>
              </Box>

              <Box gap="small">
                <Text weight="bold" textAlign="end">
                  Sub-Total:{' '}
                  <Text weight="normal" color="dark-3">
                    9000.01
                  </Text>
                </Text>

                <Text weight="bold" textAlign="end">
                  Discount:{' '}
                  <Text weight="normal" color="dark-3">
                    8%
                  </Text>
                </Text>

                <Text weight="bold" textAlign="end">
                  VAT:{' '}
                  <Text weight="normal" color="dark-3">
                    8%
                  </Text>
                </Text>
              </Box>
            </Box>

            {/* TODO: can shrink this (map) and other similar, just don't have object yet */}
            <Box gap="small">
              <Text weight="bold">
                Order Placed:{' '}
                <Text weight="normal" color="dark-3">
                  October 29, 2003
                </Text>
              </Text>
              <Text weight="bold">
                Order Status:{' '}
                <Text weight="normal" color="dark-3">
                  New/Assigned/InProgress/Closed
                </Text>
              </Text>
              <Text weight="bold">
                Order ID:{' '}
                <Text weight="normal" color="dark-3">
                  #ordernum
                </Text>
              </Text>
              <Text weight="bold">
                Order Type:{' '}
                <Text weight="normal" color="dark-3">
                  delivery/pickup
                </Text>
              </Text>
            </Box>
          </Box>

          <Box alignSelf="start">
            <Button
              label="View Details"
              color="brandAccent"
              hoverIndicator="brandAccent"
              // if we want back button on orders page
              // to go back to same open modal, will need get params in url or sessions or something
              onClick={() => history.push(`/orders/${0}/invoice`)}
            />
          </Box>
        </Box>
      </Modal>
    </ThemeContext.Extend>
  );
};

export default InvoiceModal;
