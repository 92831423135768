import { Layer, Box, Text, Button, ThemeContext } from 'grommet';
import { FormClose } from 'grommet-icons';
import React from 'react';

interface ModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  children?: JSX.Element|JSX.Element[];
  header?: string | JSX.Element;
  headerIcon?: JSX.Element;
  headerBackground?: 'default' | 'none' | string;
  width?: string | { min?: string; max?: string };
  height?: string | { min?: string; max?: string };
  exitButton?: true | false;
  headerPad?: {
    vertical?: string;
    horizontal?: string;
    top?: string;
    bottom?: string;
  };
  bodyPad?: {
    vertical?: string;
    horizontal?: string;
    top?: string;
    bottom?: string;
  };
}

const Modal = ({
  children,
  setShow,
  header,
  headerIcon,
  headerBackground = 'default',
  width = 'large',
  exitButton = true,
  headerPad,
  bodyPad,
  height,
}: ModalProps): JSX.Element => (
  <ThemeContext.Extend
    value={{
      layer: {
        border: { radius: '0px' },
      },
      global: {
        edgeSize: {
          small: '5px',
        },
        focus: { shadow: '0px 4px 4px 0px #00000040' }, // TODO: don't duplicate but theme.tsx doesn't work
      },
      textInput: {
        extend: {
          height: '2em',
        },
      },
    }}
  >
    <Layer
      full={false}
      onEsc={() => setShow(false)}
      onClickOutside={() => setShow(false)}
    >
      <Box width={width} height={height} background="light-1">
        <Box
          background={
            headerBackground === 'default' ? 'brandAccent' : headerBackground
          }
          pad={{ bottom: 'medium', ...headerPad }}
        >
          {exitButton && (
            <Button
              alignSelf="end"
              icon={<FormClose />}
              onClick={() => setShow(false)}
              margin="small"
              plain
            />
          )}
          <Box
            align="center"
            gap="small"
            pad={{ bottom: 'small', horizontal: 'medium' }}
          >
            {headerIcon}
            {typeof header === 'string' ? (
              <Text size="large" textAlign="center">
                {header}
              </Text>
            ) : (
              header
            )}
          </Box>
        </Box>
        <Box
          pad={{
            horizontal: 'medium',
            top: 'medium',
            bottom: 'large',
            ...bodyPad,
          }}
        >
          {children}
        </Box>
      </Box>
    </Layer>
  </ThemeContext.Extend>
);

export default Modal;
