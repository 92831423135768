import {
  Box,
  Button,
  Card,
  CardHeader,
  DataTable,
  ThemeContext,
  Text,
  CardBody,
} from 'grommet';
import React, { useState } from 'react';

import { useUsers } from '../../context/users';
import FinishOrderModal from '../Modals/FinishOrderModal';
import OrderModal from '../Modals/OrderModal';

// TODO: https://storybook.grommet.io/?path=/story/visualizations-datatable-custom--custom
// icons for sortable pages with props theme, extend has props. very cool

const today = new Date();

const getToday = () => {
  let month = `${(today.getMonth() + 1)}`;
  let day = `${today.getDate()}`;
  const year = today.getFullYear();
  if (month.length < 2)
        month = `0${month}`;
  if (day.length < 2) 
        day = `0${day}`;

    return [year, month, day].join('-');
}

const renderCell = (text: string | number) => <Text size="small">{text}</Text>;

const OrdersTable = ({
  type,
}: // size = 'medium',
{
  type: 'Active Orders' | 'Closed Orders' | 'Pending Orders' | "Today's Orders";
  // size?: string;
}): JSX.Element => {
  const { orders } = useUsers();
  const [showOrder, setShowOrder] = useState(false);
  const [showFinishOrder, setShowFinishOrder] = useState(false);
  const [orderID, setOrderID] = useState(0);
  
  const showModal = (id: number, status: string) => {
    if (status === "PENDING" || status === "INCOMPLETE" || status === "COMPLETED") {
    setShowOrder(true);
    setOrderID(id);
    } else if (status === "IN_TRANSIT") {
      setShowFinishOrder(true);
      setOrderID(id);
    }
  }

  const renderModal = (status: string, id: number) => (
    <Button primary margin={{right:"medium"}} onClick={() => {showModal(id, status)}}>
      <Box align="center" pad="xxsmall" wrap={true}>
        <Text size="xsmall">{status}</Text>
      </Box>
    </Button> 
  );

  const columns = [
    {
      property: 'status',
      sortable: true,
      header: 'Status',
      size: 'xsmall',
      render: ({ status, id }: { status: string, id: number }) => renderModal(status, id)
    },
    {
      property: 'jobType',
      header: 'Order Type',
      size: 'xsmall',
      render: ({ jobType }: { jobType: string }) => renderCell(jobType),
    },
    {
      property: 'id',
      header: 'Order ID',
      primary: true,
      size: 'xsmall', // slight glitch with sorting icon
      render: ({ id }: { id: number }) => renderCell(String(id).slice(0,5)),
    },
    {
      property: 'date',
      header: 'Date',
      size: 'xsmall',
      render: ({ date }: { date: string }) => renderCell(date),
    },
    {
      property: 'time',
      header: 'Time',
      size: 'xsmall',
      render: ({ time }: { time: string }) =>
        renderCell(time),
    },
    {
      property: 'tenantID',
      header: 'Tenant Email',
      size: 'xsmall',
      render: ({ tenantID }: { tenantID: string }) =>
        renderCell(tenantID),
    },
    {
      property: 'address',
      header: 'Address',
      size: 'xsmall',
      render: ({ address }: { address: {addressLine1: string} }) =>
        renderCell(address.addressLine1),
    },
    {
      property: 'driver',
      header: 'Driver',
      size: 'xsmall',
      render: ({ driver }: { driver: string }) =>
        renderCell(driver)
    },
  ];

  const filterData = () => {
    if (type === 'Active Orders') {
      return orders.filter(row => row.status !== 'COMPLETED');
    }
    if (type === 'Pending Orders') {
      return orders.filter(row => row.status === 'PENDING');
    }
    if (type === "Today's Orders") {
      return orders.filter(row => row.date === getToday());
    }
    return orders.filter(row => row.status === 'COMPLETED');
  };

  return (
    <ThemeContext.Extend
      value={{
        dataTable: {
          header: {
            border: {
              color: 'transparent',
            },
            font: {
              weight: 500,
              size: 'small',
            },
          },
        },
      }}
    >
      <Card background="light-1" height={{ max: 'medium' }} overflow="auto">
        <CardHeader pad={'small'}>
          <Text size="xlarge" color="white">
            {type}
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: 'small' }}>
          <DataTable
            data={filterData()} // TODO: right now just filters 1 fetch, alt idea is to fetch two times with filters applied
            columns={columns}
            // size={size}
            pad={{
              header: { horizontal: 'xsmall' },
              body: { vertical: 'xsmall', horizontal: 'xsmall' },
            }}
            /*
            onClickRow={({ datum }) => {
              history.push(`/orders/${datum.id}`); // not sure if this is best practice
            }}
            */
            sortable // ASK: do we want this? can't infinite scroll if so
          />
        </CardBody>
      </Card>
      {showOrder && (<OrderModal setShow={setShowOrder} orderID={orderID}/>)}
      {showFinishOrder && (<FinishOrderModal setShow={setShowFinishOrder} orderID={orderID}/>)}
    </ThemeContext.Extend>
  );
};

export default OrdersTable;
