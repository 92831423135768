import { API } from 'aws-amplify';
import { Box, TextInput, Text } from 'grommet';
// import { Edit } from 'grommet-icons';
import React from 'react';

import Modal from './Modal';
import { useUsers } from '../../context/users';
import { updateUnit, deleteUnit, updateTenant } from '../../graphql/mutations';
import StyledButton from '../StyledButton';

// TODO: probably want data validation so it's not too big
// prolly on server side

interface ModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  unitID: number;
}

const UnitModal = ({ setShow, unitID }: ModalProps): JSX.Element => {

  const saveUnit = async (idInput: number, tenantInput: string, usageInput: string, priceInput: string) => {
    const unit = {id: idInput, tenantID: tenantInput, usage: usageInput, price: priceInput}
    await API.graphql({query: updateUnit, variables: {input: unit}});
    setShow(false);
    window.location.reload();
  }

  const { units, users, boxes } = useUsers();
  const filterData = units.filter(row => row.id === unitID);
  const tenant = users.find(user => user.unitID === unitID.toString());
  const tenantBoxesInStorage = boxes.filter(row => row.tenantID === tenant?.id && row.status === 'IN_STORAGE');
  
  const [tenantValue, setTenant] = React.useState(filterData[0].tenantID);
  const [usageValue, setUsage] = React.useState(filterData[0].usage);
  const [priceValue, setPrice] = React.useState(filterData[0].price);
  
  const moveOut = async () => {

    if (tenantBoxesInStorage && tenantBoxesInStorage.length > 0) {
      alert("Tenant still has items in storage!");
      return; // Stop the function execution
  }

    const userConfirmed = window.confirm("Are you sure you want to proceed?");

    // If the user clicks "Cancel", do not proceed
    if (!userConfirmed) {
        return;
    }

    const tenantDetail = {id: tenantValue, unitID: null}
    const unitDetail = {id: unitID}

    try{
      await API.graphql({query: updateTenant, variables: {input: tenantDetail}});
      await API.graphql({query: deleteUnit, variables: {input: unitDetail}});
      window.location.reload();
    }
    catch (e: unknown) {  // TODO: rethrowing an error is a codesmell. Handle it and return appropriately.
      console.log(e);
    }
  }

  return (
      <Modal
        setShow={setShow}
        header='Edit Unit'
        >
{filterData.map(item => (
      <Box gap="medium" pad={{ horizontal: 'large' }}>
        <Box direction="column" gap="medium">
          <Box height="medium" gap="small">
            <Box direction="row" gap="medium" margin={{bottom: "medium"}}>
              <Text weight="bold">
                Unit ID:{' '}
                <Text weight="normal" color="dark-3">
                  {item.id}
                </Text>
              </Text>
              <Text weight="bold">
                Unit Size:{' '}
                <Text weight="normal" color="dark-3">
                  {item.size}
                </Text>
              </Text>
              <Text weight="bold">
                Tenant:{' '}
                <Text weight="normal" color="dark-3">
                  {item.tenantID}
                </Text>
              </Text>
            </Box>
            <Text weight="bold">
              Usage:{' '}
              <TextInput
                value={usageValue}
                onChange={event => setUsage(event.target.value)}
              />
            </Text>
            {/*<Text weight="bold">
              Price:{' '}
              <TextInput
                value={priceValue}
                onChange={event => setPrice(event.target.value)}
              />
            </Text>
            */}
          </Box>
        </Box>
        <Box direction="row" justify="between">
          <StyledButton label="Move Out" onClick={() => moveOut()} />
          <StyledButton label="Save" onClick={() => saveUnit(item.id, tenantValue, usageValue, priceValue)} />
        </Box>
      </Box>
      ))
     }
      </Modal>
  );
};

export default UnitModal;
