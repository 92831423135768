import { Avatar, Box, Text, ThemeContext } from 'grommet';
import { Deliver, Group } from 'grommet-icons';
import React, { useState } from 'react';

import CustomerDetails from './CustomerDetails';
import CustomerOrders from './CustomerOrders';
import DriverDetails from './DriverDetails';
import DriverOrders from './DriverOrders';
import { User } from '../../../context/model/types';
import DeleteUserModal from '../DeleteUserModal';
import Modal from '../Modal';

interface ModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  type: 'customer' | 'driver';
}

const renderBody = ({
  user,
  currentInfo,
  type,
}: {
  user: User;
  currentInfo: 'details' | 'orders';
  type: 'customer' | 'driver';
}) => {
  if (currentInfo === 'details') {
    if (type === 'customer') return <CustomerDetails user={user} />;
    return <DriverDetails user={user} />;
  }

  if (type === 'customer') return <CustomerOrders />;
  return <DriverOrders />;
};

const UserModal = ({ setShow, user, type }: ModalProps): JSX.Element => {
  const [currentInfo, setCurrentInfo] = useState<'details' | 'orders'>(
    'details',
  );

  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <ThemeContext.Extend
      value={{
        button: {
          border: {
            radius: '5px',
          },
        },
      }}
    >
      <Modal
        setShow={setShow}
        width={{ min: 'medium' }}
        headerBackground="none"
        bodyPad={{ bottom: 'medium', top: 'none' }}
        header={
          <>
            <Box fill direction="row" align="center" gap="medium">
              <Avatar
                size="large"
                src="https://1.gravatar.com/userimage/212100380/bfb34d02a1e842372821360907aa9583?size=256"
                border={{
                  color: type === 'customer' ? '#45BFE5' : 'brandAccent',
                  size: 'medium',
                }}
              />

              <Box
                flex
                justify="start"
                direction="row"
                alignSelf="start"
                pad={{ top: 'small' }}
                gap="medium"
              >
                <Box>
                  <Box direction="row" gap="medium">
                    <Text>{`${user.name}`}</Text>
                  </Box>
                  <Box direction="row" align="center" gap="xsmall">
                    <Text size="small" color="dark-3">
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Text>
                    {type === 'customer' ? (
                      <Group size="xsmall" color="dark-3" />
                    ) : (
                      <Deliver size="xsmall" color="dark-3" />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        }
      >
        <Box gap="medium" pad="none">
          <Box overflow={{ vertical: 'auto' }} height={{ max: 'medium' }}>
            {renderBody({ user, currentInfo, type })}
          </Box>
        </Box>
      </Modal>

      {deleteModal && (
        <DeleteUserModal
          name={`${user.name} ${user.name}`}
          setShow={setDeleteModal}
          setUserShow={setShow}
          userType="Customer"
        />
      )}
    </ThemeContext.Extend>
  );
};

export default UserModal;

/* 
Edit and delete icons

                    <Box direction="row" gap="small">
                      <Button
                        plain
                        onMouseOver={() => setEditColor(true)}
                        onMouseOut={() => setEditColor(false)}
                        icon={
                          <Edit
                            size="xsmall"
                            color={editColor ? 'status-warning' : 'plain'}
                          />
                        }
                        onClick={() => setEditing(true)}
                      />
                      <Button
                        plain
                        onMouseOver={() => setCloseColor(true)}
                        onMouseOut={() => setCloseColor(false)}
                        icon={
                          <Trash
                            size="xsmall"
                            color={closeColor ? 'status-critical' : 'plain'}
                          />
                        }
                        onClick={() => setDeleteModal(true)}
                      />
                    </Box>

View Orders Button


          <Box alignSelf="start">
            <Button
              label={currentInfo === 'details' ? 'View Orders' : 'View Details'}
              color="brandAccent"
              hoverIndicator="brandAccent"
              onClick={({ currentTarget }) => {
                if (currentInfo === 'details') {
                  setCurrentInfo('orders');
                } else {
                  setCurrentInfo('details');
                }
                currentTarget.blur();
              }}
            />
          </Box>
  */