import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardBody,
  Text,
  Heading,
} from 'grommet';
import React, { useState } from 'react';

type OrderType = 'Delivery' | 'Pickup';
type OrderState = 'In Process' | '';

interface ProgressProps {
  orderType: OrderType;
}

const Dots = () => (
  <Box flex justify="center">
    <Box
      border={{
        size: 'small',
        style: 'dotted',
        side: 'top',
      }}
    />
  </Box>
);
/*

<Box flex justify="center">
  <Box
    height="20px"
    style={{
      backgroundImage:
        'linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)',
      backgroundPosition: 'top',
      backgroundSize: '20px 5px',
      backgroundRepeat: 'repeat-x',
    }}
  />
</Box>

*/

const Progress = ({ orderType }: ProgressProps): JSX.Element => {
  const [state] = useState<OrderState>('In Process');

  return (
    <Card flex>
      <CardHeader pad="small" background="brandAccent" justify="center">
        <Heading margin="none" level="4">
          {orderType} {state}
        </Heading>
      </CardHeader>
      <CardBody
        pad={{ horizontal: 'xlarge', vertical: 'medium' }}
        background="white"
        direction="row"
        align="center"
        justify="center"
      >
        <Box align="center">
          <Avatar size="large" background="accent-1" />
          <Text>Name</Text>
          <Text size="small">Driver</Text>
        </Box>
        <Dots />
        <Box align="center">
          <Avatar size="large" background="accent-2" />
          <Text>Name</Text>
          <Text size="small">Customer</Text>
        </Box>
        <Dots />
        <Box align="center">
          <Avatar size="large" background="accent-3" />
          <Text>Storage</Text>
        </Box>
      </CardBody>
    </Card>
  );
};

export default Progress;
