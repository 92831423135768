/* eslint-disable */
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Accordion, AccordionPanel, Box, Text, Tabs, Tab, Grommet } from 'grommet';

import './styles/styles.css'
import background from '../assets/images/movers.png'
import background2 from '../assets/images/woman-on-phone-2.png'
import appStoreButton from '../assets/images/app-store-button.png'
import googleStoreButton from '../assets/images/google-play-button.png'
import slide1 from '../assets/images/Screenshots for Website/Slide1.png'
import slide2 from '../assets/images/Screenshots for Website/Slide2.png'
import slide3 from '../assets/images/Screenshots for Website/Slide3.png'
import slide4 from '../assets/images/Screenshots for Website/Slide4.png'
import slide5 from '../assets/images/Screenshots for Website/Slide5.png'

const About = () => {

  const images = [slide1, slide2, slide3, slide4, slide5];
  const indicators = (index) => (<div className="indicator">{index + 1}</div>);

return (

  <body>

      {/* HERO SECTION */}
        <section class="about" id="about">
          <div class="h2"/> 
          <div class="content">
            <h3>
            Let's Make Storage Easy
            </h3>
            <p class="a">
              Moving and storage is often stressful, time-consuming, and inefficient.
            </p>
            <p>
              We help solve these issues by transforming unwanted units into “valet” units, making storage easy for tenants and storage facilities.
            </p>
            <a href="/Download" class="button1">
                  Download the App
            </a>
          </div>
          <div class="image">
            <img src={background}/>
          </div>
        </section>

        <section class="slides" id="slides">
          <div class="smallcontainer">
            <h2>How<span class="purpleColor"> Bins Works</span></h2>
            <p class="a">Through our app, tenants can find a storage facility, view stored items, and request deliveries or pickups. Our technology helps coordinate communication between the tenant, facility, and drivers, making the process seamless and simple.</p>
          </div>
          <div class="slideContainer">
           <Slide indicators={indicators} autoplay={false} transitionDuration={300}>
                  <div className="each-slide-effect">
                      <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                      </div>
                  </div>
                  <div className="each-slide-effect">
                      <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                      </div>
                  </div>
                  <div className="each-slide-effect">
                      <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                      </div>
                  </div>
                  <div className="each-slide-effect">
                      <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                      </div>
                  </div>
                  <div className="each-slide-effect">
                      <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                      </div>
                  </div>         
              </Slide>
            </div>
        </section>

        <section class="faq" id="faq">
          <h2>Frequently<span class="purpleColor"> Asked Questions</span></h2>

          <div className="container">
           <div className='spacer'/>
            <Box className="custom-panel">
              <Accordion width="large">
                <AccordionPanel  label={<Text className="ralewayBold">What is Bins Storage?</Text>}>
                  <Text className="raleway">Bins is a platform that connects storage facilities, customers, and drivers. Our platform facilitates valet/full-service storage, offering tenants the option to have items picked up or delivered, removing the hassle of having to lift or drive.</Text>
                </AccordionPanel>

                <AccordionPanel  label={<Text className="ralewayBold">Where is Bins Offered?</Text>}>
                  <Text className="raleway">Currently, we are in the San Francisco Bay area, but we are planning on expanding to other regions soon.</Text>
                </AccordionPanel>

                <AccordionPanel  label={<Text className="ralewayBold">What’s the difference between valet storage and self storage?</Text>}>
                  <Text className="raleway">With self storage, the tenant is responsible for everything - packing, driving, and moving into the unit. With valet storage, drivers will pick up or deliver your items, removing the need to physically go to the storage facility.</Text>
                </AccordionPanel>

                <AccordionPanel  label={<Text className="ralewayBold">How do I get started?</Text>}>
                  <Text className="raleway">Click <a href="/Download">here</a> to download the app and follow the instructions to get started.</Text>
                </AccordionPanel>
              </Accordion>
              <a href="/FAQ" class="button1">
                  See all FAQs
              </a>
            </Box>
            <div class="image">
              <img src={background2}/>
            </div>
          </div>
        </section>
  </body>
  
)
}

export default About
