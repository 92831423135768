import {
  Box,
  Grid,
  Pagination,
  PaginationProps,
  ResponsiveContext,
} from 'grommet';
import React, { useEffect, useState, useContext } from 'react';

import { Driver } from '../context/model/types';

interface PaginatedGridProps {
  data: Driver[];
  card: (user: Driver) => JSX.Element;
}

export const DriverCardGrid = ({
  data,
  card,
}: PaginatedGridProps): JSX.Element => {
  const step = 12;
  const [currentData, setCurrentData] = useState<Driver[]>([]);
  const size = useContext(ResponsiveContext);
  // TODO how does grid look with < step elements
  useEffect(() => {
    // TODO for some reason current data cannot be set at useState
    setCurrentData(data.slice(0, step));
  }, [data, step]);

  const handleChange: PaginationProps['onChange'] = ({
    startIndex,
    endIndex,
  }) => {
    const nextData = data.slice(startIndex, endIndex);
    setCurrentData(nextData);
  };

  const adjustColumnNum = () => {
    switch (size) {
      case 'small':
        return 1;
      case 'medium':
        return 2;
      default:
        return 4;
    }
  };

  return (
    <Box pad={{ horizontal: 'small', top: 'small' }} gap="medium">
      <Grid
        columns={{ count: adjustColumnNum(), size: 'auto' }}
        rows="xsmall"
        gap="medium"
        justify="center"
      >
        {currentData.map(datum => card(datum))}
      </Grid>

      <Pagination
        step={step}
        numberItems={data.length}
        alignSelf="end"
        onChange={handleChange}
      />
    </Box>
  );
};

export default DriverCardGrid;
