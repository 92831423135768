/* eslint-disable */
import React from 'react';

import './styles/styles.css'
import background from '../assets/images/woman-on-phone-1.png'
import screenshots from '../assets/images/how-bins-works-4.png'
import screenshots2 from '../assets/images/facility-owners.png'

import appStoreButton from '../assets/images/app-store-button.png'
import googleStoreButton from '../assets/images/google-play-button.png'

const Landing = () => (
        <body>
          <div>

        {/* HERO SECTION */}
        <section class="home" id="home">
          <div class="h2"/> 
          <div class="content">
            <h3>
              <span class="altColor">Smooth & Stree-Free</span> Storage
            </h3>
            <p class="a">
              Our platform facilitates full-service storage for businesses and customers. 
            </p>
            <p>
              Quickly store items or have them delivered, without leaving your home.
            </p>
            <p>
              Learn More
            </p>
            <div class="down-arrow"></div>
          </div>
          <div class="image">
            <img src={background}/>
          </div>
        </section>

        {/* TENANT SECTION */}
        <section class="tenants" id="tenants">
        <div class ="paddingContainer"></div>
          {/* Image */}
          <div class="image">
            <img src={screenshots}/>
          </div>
          {/* Text */}
          <div class="content">
            <h1>
              FOR TENANTS
            </h1>
            <h2>
              Hassle-Free, <span class="purpleColor">Convenient Storage</span>
            </h2>
            <p>
              Our app provides tenants with the ability to virtually view items in storage, then schedule deliveries. Bins provides convinence and eliminates the need to pack, lift, or drive. Customers can see everything in storage without having to dig through their unit.
            </p>
            <div class="buttonContainer">
              <a href="/Download" class="button1">
                  Download the App
              </a>
              <a href="/About" class="button2">
                  Learn More
              </a>
            </div>
          </div>
        </section>

        {/* FACILITIES SECTION */}
        <section class="facilities" id="facilities">
        <div class ="paddingContainer"></div>
          {/* Text */}
          <div class="content">
            <h1>
              FOR FACILTIES
            </h1>
            <h2>
              Smart Space Optimization
            </h2>
            <p>
              Bins provides storage facilities with the technology and management system to implement valet storage, increasing revenues by filling vacancies and expanding the market. All without any additional cost. 
            </p>
            <div class="buttonContainer">
              <a href="https://forms.gle/88mtu637wESKE5dJ9" class="button1">
                    Contact Us
              </a>
              <a href="/About" class="button2">
                    Learn More
              </a>
            </div>
          </div>
          {/* Image */}
          <div class="image">
            <img src={screenshots2}/>
          </div>
        </section>
        </div>
        </body>
)

export default Landing;
